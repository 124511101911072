import React from "react";
import Typography from "../../../../components/ui/typography";
import classNames from "./style.module.css";

const conditions = (
  <ol className="orange">
    <li>
      <Typography size="smaller" color="white">
        Şampiyona “İlkokul <span className="grey">(3-4. sınıflar)</span>”,
        “Ortaokul <span className="grey">(5-6-7-8. sınıflar)</span>” ve “Lise{" "}
        <span className="grey">(9-10-11-12. sınıflar)</span>” olmak üzere üç ana
        kategoride on sınıf üzerinden yapılır.
      </Typography>
    </li>
    <li>
      <Typography size="smaller" color="white">
        Okullar tüm sınıflarda ya da uygun gördükleri sınıflarda şampiyonaya
        katılabilir.
      </Typography>
    </li>
    <li>
      <Typography size="smaller" color="white">
        Her okul, şampiyonaya katılacakları kategori (ilkokul/ortaokul/lise)
        için bir takım sorumlusu öğretmen belirler. Bir öğretmen birden fazla
        kategoride takım sorumlusu olabilir.
      </Typography>
    </li>
    <li>
      <Typography size="smaller" color="white">
        Her kategori için okul kaydını okul takım sorumlusu öğretmen yapar ve
        gerekli bilgileri şampiyonanın internet sayfası olan
        https://sampiyona.tzv.org.tr adresine girer. Öğrenci kayıtları ise
        öğrenciler tarafından yapılır. İl Finalleri Sınavı sonuçlarına göre
        Bölge Finalleri Sınavında sınıf takımlarında yer almaya hak kazanan
        öğrencilerin listesi, okul sorumlusu öğretmen sayfasında yayınlanır.
        Okul sorumlusu öğretmenler, listede uygunsuzluk tespit etmeleri halinde
        değişiklik taleplerini sayfadaki itiraz alanını doldurarak iletirler.
      </Typography>
    </li>
    <li>
      <Typography size="smaller" color="white">
        Tüm okulların şampiyonaya katılımları ücretsizdir. Kırtasiye giderleri
        yarışmacılara aittir.
      </Typography>
    </li>

    <li>
      <Typography size="smaller" color="white">
        Şampiyona “İl Finalleri”, “Bölge Finalleri” ve “Türkiye Finalleri” olmak
        üzere üç aşamada gerçekleştirilir. Tüm sınavlar şampiyona takviminde
        belirtilen tarihlerde internet üzerinden yapılır. (Koşullar uygun olduğu
        takdirde Türkiye Finalleri Sınavı yazılı olarak Ankara’da yapılacaktır.)
      </Typography>
    </li>
    <li>
      <Typography size="smaller" color="white">
        İl Finallerine tüm okullardan gönüllülük esasına göre her öğrenci
        katılabilir. Katılacak öğrenci sayısı için bir üst sınır yoktur. Bu
        sınavda herkes bireysel olarak yarışır.
      </Typography>
    </li>
    <li>
      <Typography size="smaller" color="white">
        Şampiyonaya katılmak isteyen engelli bireylerin yanlarında iletişime
        katkı sağlayacak bir yardımcı bulunabilir.
      </Typography>
    </li>
    <li>
      <Typography size="smaller" color="white">
        İl Finalleri Sınavında kendi okulunun ilgili sınıfında ilk 3 sırayı alan
        öğrenciler asıl, sonraki 3 öğrenci ise yedek olarak o sınıfa ait okul
        takımını oluştururlar. Eşitlik durumunda okul tercihi veya doğum tarihi
        dikkate alınır (küçük yaş önceliklidir). Takımlar 1 ya da 2 öğrenciyle
        de oluşabilir.
      </Typography>
    </li>
    <li>
      <Typography size="smaller" color="white">
        İl Finalleri Sınavında her okulun ilgili sınıfta ilk 3 sırayı alan
        öğrencilerinin puan ortalaması o okulun o sınıftaki Okul İl Puanı olarak
        kabul edilir.
      </Typography>
    </li>
    <li>
      <Typography size="smaller" color="white">
        Bölge Finalleri Sınavına her ilde İl Finalleri Sınavında ilk 5 sırayı
        alan okullar ve ek olarak İl Finalleri Sınavında ilk %30’luk dilime
        giren bütün okullar katılır. Bölge Finalleri Sınavında okul
        takımlarındaki öğrenciler sanal ortamda yardımlaşarak, takım halinde
        yarışırlar. Tüm takım üyeleri, takım adına cevap girişi yapabilir.
        Sınavda alınan puan, o okulun o kategorideki Okul Bölge Puanı’dır.
      </Typography>
    </li>
    <li>
      <Typography size="smaller" color="white">
        Türkiye Finalleri Sınavına ülkemizin 7 coğrafi bölgesi dikkate alınarak
        her bölgedeki Bölge Finalleri Sınavında ilk 5 sırayı alan 35 okul ve bu
        okullar dışında Bölge Finalleri Sınavında ilk sırayı alan 65 okul
        (toplam 100 okul) katılır. Türkiye Finalleri Sınavında okul
        takımlarındaki öğrenciler, sanal ortamda yardımlaşarak, takım halinde
        yarışırlar. Tüm takım üyeleri, takım adına cevap girişi yapabilir.
        Sınavda alınan puan, o okulun o sınıftaki Okul Türkiye Puanı’dır.
      </Typography>
    </li>

    <li>
      <Typography size="smaller" color="white">
        Tüm sınavlar için başlangıç-bitiş tarihleri ve saatleri şampiyona
        takviminde yer almaktadır. Sınav saati geldiğinide öğrenciler evlerinden
        ya da okulun sağladığı bir mekândan sınava katılabilirler. Öğretmenler,
        veliler ve diğer öğrenciler yarışmacılara yardım edemezler. Yarışmacılar
        internet erişimine uygun bir ortamın sağlanmasından sorumludur. Herhangi
        bir nedenle doğabilecek teknik problem nedeniyle sınav sorularına
        ulaşılamaması, bağlantının kesilmesi ya da cevapların belirlenen süre
        içinde yollanamaması gibi nedenlerden dolayı TZV farklı bir işlem
        yapamaz. Böyle bir durumla karşılaşan yarışmacılar şampiyona dışı kalır.
        Sınav sonuçları şampiyona internet sayfasında yayınlanır.
      </Typography>
    </li>

    <li>
      <Typography size="smaller" color="white">
        Okulların ilgili kategoride (ilkokul/ortaokul/lise) sınıf bazında elde
        ettikleri ilk üç derece (önce birincilik, sonra ikincilik, daha sonra
        üçüncülük sayıları dikkate alınarak) sıralanır ve ilan edilir.
      </Typography>
    </li>
    <li>
      <Typography size="smaller" color="white">
        Şampiyonada her sınıfta ilk üç dereceye giren okullara kupa,
        öğrencilerine ve öğretmenlerine madalya verilir. Ayrıca;
        <ul>
          <li>
            <Typography size="smaller" color="white">
              Türkiye Finallerinde yarışan tüm okullara, öğrencilerine ve
              öğretmenlerine elektronik “Türkiye Finalleri Başarı Belgesi”,
            </Typography>
          </li>
          <li>
            <Typography size="smaller" color="white">
              Bölge Finallerinde yarışan tüm okullara, öğrencilerine ve
              öğretmenlerine elektronik “Bölge Finalleri Başarı Belgesi”,
            </Typography>
          </li>
          <li>
            <Typography size="smaller" color="white">
              İl Finalleri Sınavında ilgili sınıfta okulunun ilk %10’luk
              dilimine giren öğrencilere elektronik “İl Finalleri Başarı
              Belgesi”,
            </Typography>
          </li>
          <li>
            <Typography size="smaller" color="white">
              Şampiyonaya katılan tüm öğrencilere elektronik “Katılım Belgesi”
              verilir.
            </Typography>
          </li>
        </ul>
      </Typography>
    </li>
    <li>
      <Typography size="smaller" color="white">
        Şampiyona sınavları ve değerlendirilmesi, Türkiye Zeka Vakfı Soru Kurulu
        tarafından, Türkiye Zeka Vakfı Yarışma Standartlarına göre yapılır.
        Sınavlar 10 soru ve eşitlik bozmada kullanılacak ek sorulardan oluşur.
        Her soru 10 puandır. Ayrıca soruların sınıf bazında çözülme yüzdelerine
        göre (0 ile 10 arasında) ek puan verilir. Bir sorunun doğru çözülme
        yüzdesi D ise o sorudan alınacak puan = 10 + 10x(1-D/100) olarak
        hesaplanır. Ayrıntılı bilgi şampiyona sayfasında yer almaktadır.
      </Typography>
    </li>

    <li>
      <Typography size="smaller" color="white">
        6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında Şampiyonaya
        kayıt sağlayanlardan kategorilerini belli etmek amacıyla açık rıza
        onayıyla alınan bilgiler, üçüncü şahıslarla hiçbir ad altında
        paylaşılmayacak ve etkinliğin sona ermesinin ardından resen
        silinecektir.
      </Typography>
    </li>

    <li>
      <Typography size="smaller" color="white">
        Şampiyonaya katılan 18 yaşından küçük katılımcılardan velisinin izni
        alınır.
      </Typography>
    </li>

    <li>
      <Typography size="smaller" color="white">
        Şampiyonayla ilgili tüm bilgiler ve gelişmeler şampiyona internet
        sayfalarında duyurulur.
      </Typography>
    </li>
    <li>
      <Typography size="smaller" color="white">
        Türkiye Zeka Vakfı, gerekli hallerde, Millî Eğitim Bakanlığından onay
        almak şartıyla şampiyona koşullarında ve takviminde değişiklik
        yapabilir.
      </Typography>
    </li>

    <li>
      <Typography size="smaller" color="white">
        Şampiyonaya katılan okullar, öğretmenler ve öğrenciler bu koşulları
        kabul etmiş sayılırlar.
      </Typography>
    </li>
    <div style={{ width: "100%", textAlign: "center", marginTop: "60px" }}>
      <Typography
        color="white"
        style={{ fontStyle: "italic", fontSize: "1.1rem" }}
      ></Typography>
    </div>
  </ol>
);

const Conditions = () => (
  <div className={classNames.conditions}>
    <Typography color="orange" size="bigger">
      Koşullar
    </Typography>
    <div className={"card " + classNames.card}>{conditions}</div>
    <img
      src="/assets/images/operator.png"
      alt="operator"
      className={classNames.operator}
    />
  </div>
);

export default Conditions;
