import React from "react";
import classNames from "../manage.module.css";
import LabelAndText from "../../../components/ui/labelAndText/labelAndText";
import MaterialButton from "@material-ui/core/Button";
import SettingsIcon from "@material-ui/icons/Settings";
import { ListAltOutlined } from "@material-ui/icons";
import CryptoJS from "crypto-js";
import Typography from "../../../components/ui/typography";

export const UserProfileSummaryBox = ({
  user,
  onUserProfileEditClick,
  coachNameOfStudent,
}) => {
  const schoolName =
    user && user.school_name
      ? user.school_name + " (" + user.city + "/" + user.district + ")"
      : "";

  const coachClasses = user.all_coaches_of_this_school
    .sort((a, b) => a.class_id - b.class_id)
    .map((u) => {
      if (u.id === user.id) return " " + u.class_id + ".Sınıf";
    })
    .filter((u) => u)
    .toString();

  const generateScreenId = (userId) => {
    const hash = CryptoJS.SHA256(userId + "tozos").toString();
    return `${userId}tozos${hash.substring(0, 5)}`;
  };
  const screenId = user ? generateScreenId(user.id) : "";
  const documentsUrl = `https://belge-yonetim.web.app/screens/${screenId}`;

  return (
    <div className={"card " + classNames.left_step}>
      <LabelAndText labelText={""} text={schoolName} />
      <LabelAndText labelText={"Adı-Soyadı"} text={user.name} />
      <LabelAndText labelText={"E-Posta"} text={user.email} />
      <LabelAndText
        isHidden={user.role !== 1}
        labelText={"Sınıflarınız"}
        text={
          coachClasses ??
          "Öğrenci listesine ulaşabilmek için lütfen 'Düzenle' butonuna basarak sınıf seçimi yapınız."
        }
      />
      <LabelAndText
        isHidden={user.role !== 0}
        labelText={"Kategori"}
        text={
          user.class_id
            ? user.class_id + ". Sınıf"
            : "Lütfen 'Düzenle' butonuna basarak okul ve sınıf bilgilerinizi güncelleyiniz."
        }
      />
      <LabelAndText
        isHidden={user.role !== 0}
        labelText={"Yarışmacı ID"}
        text={user.id}
        title={
          "Bu numara yarışma başvuru numaranızdır, otomatik olarak verilmektedir."
        }
      />
      <LabelAndText
        isHidden={user.role !== 0}
        labelText={"Sorumlu Öğretmen"}
        text={
          coachNameOfStudent ??
          "Okulunuzdan sorumlu öğretmen kaydı yapılmamıştır. "
        }
      />
       {user.role === 0 && !coachNameOfStudent && (
        <Typography
          style={{
            fontStyle: "italic",
            color: "orange",
            marginTop: 10,
            marginBottom: 16,
            fontSize: "16px",
          }}
        >
          {
            "Okul kaydı/Sorumlu Öğretmen kaydı öğrenciler tarafından yapılamaz. Kayıt, okul yönetimi tarafından sampiyona.tzv.org.tr adresindeki 'Sorumlu Öğretmen' butonu kullanılarak yapılmalıdır. Okulunuzda herhangi bir öğretmen (müdür, müdür yardımcısı, rehber öğretmen, sınıf öğretmeni vb.) kaydınızın yapılması için yetkilidir. Eğer okula bilgi vermenize ve kaydınızın yapılması gerektiğini iletmenize rağmen kayıt gerçekleştirilmemişse, bu durum sorun teşkil etmemektedir."
          }
        </Typography>
      )}
      <div>
        <MaterialButton
        //sınavdan sonra açılacak
        // disabled={user.role === 0}
          variant="contained"
          color="secondary"
          style={{ backgroundColor: "#6b6a5f", marginTop: 20, marginRight: 10 }}
          startIcon={<SettingsIcon />}
          onClick={onUserProfileEditClick}
        >
          Düzenle
        </MaterialButton>
        <a href={documentsUrl} target="_blank" rel="noopener noreferrer">
          <MaterialButton
            variant="contained"
            color="secondary"
            style={{ backgroundColor: "#6b6a5f", marginTop: 20 }}
            startIcon={<ListAltOutlined />}
          >
            BELGELERİM
          </MaterialButton>
        </a>
      </div>
    </div>
  );
};
