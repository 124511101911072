import React, { useEffect, useState } from "react";
import MaterialBackDrop from "../../../../components/ui/loading/materialBackDrop";
import Typography from "../../../../components/ui/typography";
import { pullFilePaths } from "../../../../firebaseMethods";
import classNames from "./style.module.css";

import { animateScroll } from "react-scroll";
import Collapsable from "../../../../components/ui/collapsable/collapsable";
import ScrollToTop from "../../../../components/scrollToTop/scrollToTop";

const DetailedUpdates = () => {
  const [params, setParams] = useState({
    bolgeFinaliSonucListesi: null,
    trFinaliSonucListesi: null,
    ilFinaliSonucListesi: null,
    trFinaliSonucListesi: null,
    activeIndex: 5,
  });

  const setParam = (key, value) => {
    setParams({
      ...params,
      [key]: value,
    });
  };
  const activeIndex = params.activeIndex;

  useEffect(() => {
    pullFilePathFromFirebase();
  }, []);

  const toggleCollapse = (show, index) => {
    if (activeIndex === index) setParam({ activeIndex: -1 });
    else setParam({ activeIndex: index });
    animateScroll.scrollToTop();
  };

  const pullFilePathFromFirebase = async () => {
    const allResults = await pullFilePaths("tozos_24_25_sonuclar");
    const ilFinaliSonucListesi = allResults?.il_finalleri;
    const bolgeFinaliSonucListesi = allResults?.bolge_finalleri;
    const trFinaliSonucListesi = allResults?.tr_finalleri;
    const trFinaliCevapAnahtarlari = allResults?.tr_finalleri;
    // const trFinaliSonucListesi = await pullFilePaths("tozos_20-21_tr_finali_sonuc_listesi");
    // const bolgeFinaliSonucListesi = await pullFilePaths("tozos_20-21_bolge_finali_sonuc_listesi");

    // setParam("trFinaliSonucListesi", trFinaliSonucListesi);
    // setParam("bolgeFinaliSonucListesi", bolgeFinaliSonucListesi);

    setParams({
      ...params,
      ilFinaliSonucListesi,
      trFinaliSonucListesi,
      bolgeFinaliSonucListesi,
      trFinaliCevapAnahtarlari,
    });
  };

  const pilotTestAnnouncement = (
    <div className={"card " + classNames.long_description}>
      <div
        style={{
          whiteSpace: "pre-line",
          fontSize: "1rem",
          fontFamily: "sans-serif",
        }}
      >
        {`
        İL FİNALLERİ SINAVI HAKKINDA BİLGİLENDİRME

        Değerli Yarışmacımız,
        1- İl Finalleri Sınavı, 19 Şubat 2022, Cumartesi günü saat 13.00’te başlayacaktır. Sınav süresi 90 dakikadır.
        2- Sınav günü geldiğinde profil sayfanızda yer alan “İl Finalleri Sınavı” butonuna tıklayarak sınav ekranına ulaşabilirsiniz. 
        3- Sınav 10 asıl soru ve 20 ek soru olmak üzere toplam 30 soru içermektedir. İlk değerlendirme asıl sorulardan aldığınız puana göre yapılacaktır. Asıl sorulardan aynı puanı alan yarışmacılar kendi aralarında sıralanırken ek sorulardan aldıkları puana bakılacaktır.
        4- Cevap girişi yaptığınızda yanıtınız otomatik olarak kaydedilecektir. Sınav sonunda "Sınavı Bitir" benzeri bir buton olmayacaktır.
        5- Sınav sayfası dışında (e-posta, faks vb.) gönderilen cevaplar kabul edilmeyecektir.
        6- Sınav öncesi hesabınıza giriş yaparak bilgilerinizin doğru olup olmadığını kontrol ediniz! Okul ve sınıf bilginizin hatalı olması diskalifiye olmanıza sebep olacaktır.
        
        Tüm yarışmacılara başarılar dileriz.
        Saygılarımızla,

        Türkiye Zeka Vakfı
        Şampiyona Takımı`}
      </div>
    </div>
  );

  const second_pilotTestAnnouncement = (
    <div className={"card " + classNames.long_description}>
      <Typography size="small">Değerli Yarışmacımız,</Typography>
      <Typography size="smaller">
        <p>
          28 Kasım 2020 Cumartesi günü internet üzerinden yapılacak olan İl
          Finalleri Sınavının Denemesi 26 Kasım 2020 Perşembe günü yeni kayıt
          olan yarışmacıların katılımıyla tekrarlanacaktır.
        </p>
        <p>
          Yapılacak olan bu deneme sınavı, asıl sınavın provası niteliğinde olup
          3 sorudan oluşan bir örnek sınavdır ve cevaplar değerlendirmeye
          alınmayacaktır.
        </p>{" "}
        <p>
          Daha önce katılım sağlayan yarışmacılarımızın tekrar niteliğindeki bu
          deneme sınavına katılımı zorunlu değildir.
        </p>{" "}
        <p>
          Amacımız; yarışmacıların sınav sürecini eksiksiz anlamaları ve olası
          durumları tecrübe ederek ön hazırlık yapmalarını sağlamaktır.
        </p>{" "}
        <p>
          Deneme Sınavı, esas sınavın provası niteliğinde olacaktır. Aşağıdaki
          adımları dikkatlice okumanız ve belirtilen hazırlıkları yapmanız,
          sınav günü sorun yaşamamanız için büyük önem arz etmektedir.
        </p>{" "}
        <p>
          <b>NOT:</b> Yarışmacıların başvurularının tamamlanması ve 28 Kasım’da
          internet üzerinden gerçekleştirilecek olan İl Finalleri Sınavına
          katılmaları için kırtasiye giderini ödemeleri gerekmektedir. Kırtasiye
          giderini ödemediyseniz profil sayfanızdan ödemeyi
          gerçekleştirebilirsiniz.
        </p>
      </Typography>
      <ol className="orange">
        <li>
          <Typography size="smaller">
            Deneme Sınavı, 26 Kasım Perşembe Saat 14.00’da şampiyona web
            sayfasında yayınlanacaktır.{" "}
          </Typography>
        </li>
        <li>
          <Typography size="smaller">
            Sınava erişim için https://sampiyona.tzv.org.tr adresine
            kaydolduğunuz e-posta ve şifre ile giriş yapmalısınız.{" "}
          </Typography>
        </li>
        <li>
          <Typography size="smaller">
            Giriş yaptıktan sonra sağ üstte bulunan (mobil cihazlar için sağ
            üstte bulunan menü içerisinde) “Şampiyona” butonuna tıklayarak
            şampiyona sayfasına girmelisiniz.{" "}
          </Typography>
        </li>
        <li>
          <Typography size="smaller">
            Açılan sayfada yer alan “Deneme Sınavı” butonuna tıklayarak deneme
            sınavına erişim sağlayabilirsiniz. (En iyi kullanım için bilgisayar
            üzerinden girmenizi öneririz.){" "}
          </Typography>
        </li>
        <li>
          <Typography size="smaller">
            Deneme Sınavı formunda ilk olarak e-posta adresinizi ve ad-soyad
            bilginizi doldurunuz. Yarışmacı ID kısmı otomatik girilecektir.{" "}
          </Typography>
        </li>
        <li>
          <Typography size="smaller">
            Sınav çoktan seçmeli olacaktır. Her bir soru için doğru cevabı soru
            altında bulunan şıklardan seçerek işaretlemelisiniz.{" "}
          </Typography>
        </li>
        <li>
          <Typography size="smaller">
            Sınav saat 15:30’da cevap girişine kapatılacaktır. Bu sebeple en geç
            15:30’a kadar formun en altında bulunan “Gönder” butonuna
            basmalısınız. Cevaplarınızı gönderdiğinizde açılan sayfada
            “Yanıtları Düzenle” butonuna basarak sınav süresi sonuna kadar
            cevaplarınızı güncelleyebilirsiniz.{" "}
          </Typography>
        </li>
        <li>
          <Typography size="smaller">
            Cevaplarınızı bir yere kaydetmenizi ve son dakikaya bırakmadan
            girmenizi tavsiye ederiz.{" "}
          </Typography>
        </li>
        <li>
          <Typography size="smaller">
            “Gönder” butonuna basmadan sayfayı yenilerseniz, kapatırsanız ya da
            çıkıp tekrar girerseniz cevaplarınız kaybolacaktır. Tekrar tüm
            cevapları girmeniz gerekecektir.{" "}
          </Typography>
        </li>
        <li>
          <Typography size="smaller">
            Form dışında (e-posta, faks vb.) gönderilen cevaplar kabul
            edilmeyecektir.{" "}
          </Typography>
        </li>
      </ol>
      <p>Tüm yarışmacılara başarılar dileriz.</p>
    </div>
  );

  const ilFinaliOncesiHatirlatma = (
    <div className={"card " + classNames.long_description}>
      <Typography size="small">Değerli Yarışmacımız,</Typography>
      <Typography size="smaller">
        <p>
          İl Finalleri Sınavı 28 Kasım 2020 Cumartesi günü saat 14.00'te
          sampiyona.tzv.org.tr adresi üzerinden yapılacaktır. Sınav süresi 90
          dakikadır.
        </p>
        <p>
          Aşağıdaki adımları dikkatlice okumanız ve belirtilen hazırlıkları
          yapmanız, sınav günü sorun yaşamamanız için büyük önem arz etmektedir.
        </p>
        <p>
          <b>DİKKAT!:</b> Sınav öncesi hesabınıza giriş yaparak bilgilerinizin
          doğru olup olmadığını kontrol ediniz! Yanlış girişlerden kaynaklı
          oluşabilecek durumlardan yarışmacı sorumlu olacaktır.
        </p>
        <p>
          <b>NOT:</b> Yarışmacıların başvurularının tamamlanması ve 28 Kasım’da
          internet üzerinden gerçekleştirilecek olan İl Finalleri Sınavı'na
          katılmaları için kırtasiye giderini ödemeleri gerekmektedir. Kırtasiye
          giderini ödemediyseniz en geç 27 Kasım gece 23.59'a kadar profil
          sayfanızdan ödemeyi gerçekleştirebilirsiniz.
        </p>
        <p>
          <b>Sınav Süreci ve Uyarılar</b>
        </p>
        <p>
          1- Sınavı, 28 Kasım 2020 Cumartesi günü Saat 14.00’te şampiyona web
          sayfasında yayınlanacaktır.
        </p>
        <p>
          2- Sınava erişim için https://sampiyona.tzv.org.tr adresine
          kaydolduğunuz e-posta ve şifre ile giriş yapmalısınız.
        </p>
        <p>
          3- Giriş yaptıktan sonra sağ üstte bulunan (mobil cihazlar için sağ
          üstte bulunan menü içerisinde) “Şampiyona” butonuna tıklayarak
          şampiyona sayfasına girmelisiniz.
        </p>
        <p>
          4- Açılan sayfada yer alan “İl Finalleri Sınavı” butonuna tıklayarak
          sınava erişim sağlayabilirsiniz. (En iyi kullanım için bilgisayar
          üzerinden girmenizi öneririz.)
        </p>
        <p>
          5- Sınav formunda ilk olarak e-posta adresinizi giriniz. Adı Soyadı ve
          Yarışmacı ID kısmı otomatik girilecektir.
        </p>
        <p>
          6- Sınav çoktan seçmeli olacaktır. Her bir soru için doğru cevabı soru
          altında bulunan şıklardan seçerek işaretlemelisiniz.
        </p>
        <p>
          7- Sınav saat 15.30’da cevap girişine kapatılacaktır. Bu sebeple en
          geç 15.30’a kadar formun en altında bulunan “Gönder” butonuna
          basmalısınız. Cevaplarınızı gönderdiğinizde açılan sayfada “Yanıtları
          Düzenle” butonuna basarak sınav süresi sonuna kadar cevaplarınızı
          güncelleyebilirsiniz.
        </p>
        <p>
          8- Cevaplarınızı bir yere kaydetmenizi ve son dakikaya bırakmadan
          girmenizi tavsiye ederiz.
        </p>
        <p>
          9- “Gönder” butonuna basmadan sayfayı yenilerseniz, kapatırsanız ya da
          çıkıp tekrar girerseniz cevaplarınız kaybolacaktır. Tekrar tüm
          cevapları girmeniz gerekecektir.
        </p>
        <p>
          10- Form dışında (e-posta, faks vb.) gönderilen cevaplar kabul
          edilmeyecektir.
        </p>
        <p></p>
        <p>Tüm yarışmacılara başarılar dileriz.</p>
      </Typography>
    </div>
  );

  const trFinaliSonuclari = (
    <div className={classNames.itemBody}>
      <Typography size="smaller" style={{ textAlign: "right" }}>
        16.05.2024
      </Typography>

      <p>
        Türkiye Finalleri Sınavı'na ait tüm sonuçlar aşağıda yayınlanmıştır.
      </p>
      <p>
        Puanları ve ek soru puanları aynı olan takımların derecesi aynı olacak
        şekilde derecelendirme yapılmış, bir önceki sınavın sonuçları
        hesaplamaya dahil edilmemiştir.
      </p>
      <p>
        Koşullarda belirtildiği üzere her sınıftan ilk 3 dereceye girmiş olan
        takımlar kupa, takım üyeleri ve sorumlu öğretmenleri madalya hakkı
        kazanmıştır. Ödüller, 22 Mayıs 2024, Çarşamba günü saat 13.00’te
        Ankara'da MEB Şûra Salonu’nda takdim edilmiştir.
      </p>
      <p>
        İlk 3 dereceye giren takım üyeleri 29 Mayıs Çarşmaba günü 10.50-14.40
        saatleri arasında zoom üzerinden yapılacak olan Düşünce Şenliği'ne
        katılım hakkı kazanmıştır. İlk 3 dereceye giren öğrencilerin e-posta
        adreslerine zoom linki iletilmiştir.
      </p>
      <p>
        Sınava katılan tüm öğrenciler için katılım/başarı belgeleri ve sosyal
        etkinlik bilgilendirme formları sisteme yüklenmiştir.
      </p>

      {params.trFinaliSonucListesi && (
        <div
          style={{
            fontSize: "1.2rem",
            display: "flex",
            flexDirection: "column",
            width: 250,
          }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((sinif) => {
            return (
              <a
                target="_blank"
                rel="noopener noreferrer" // Add this line
                href={params.trFinaliSonucListesi["sinif_" + sinif]}
                download
                key={"sinif_tr_link" + sinif}
              >
                {sinif + ". Sınıflar"}
              </a>
            );
          })}
        </div>
      )}
      {!params.bolgeFinaliSonucListesi && <MaterialBackDrop />}
    </div>
  );

  const trFinaliOkulPuanlari = (
    <div className={classNames.itemBody}>
      <Typography size="smaller" style={{ textAlign: "right" }}>
        25.05.2022
      </Typography>

      <p>Türkiye Finalleri Sınavı okul puanları yayınlanmıştır.</p>
      <p>
        Ortaokullar için 1.soruda 0 cevabı da kabul edilmiştir. Her soru için
        doğru cevaplar, dinamik hesaplanan soru puanları ve nihai puanlar
        gösterilmiştir.
      </p>

      {params.trFinaliSonucListesi && (
        <div
          style={{
            fontSize: "1.2rem",
            display: "flex",
            flexDirection: "column",
            width: 250,
          }}
        >
          {/* <a
            target="_blank"
            href={params.trFinaliSonucListesi.kategori_dereceleri}
            download
          >
            Kategori Dereceleri
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi.sinif_dereceleri}
            download
          >
            Sınıf Dereceleri
          </a> */}
          <a
            target="_blank"
            href={params.trFinaliSonucListesi.sinif_1}
            download
          >
            1. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi.sinif_2}
            download
          >
            2. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi.sinif_3}
            download
          >
            3. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi.sinif_4}
            download
          >
            4. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi.sinif_5}
            download
          >
            5. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi.sinif_6}
            download
          >
            6. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi.sinif_7}
            download
          >
            7. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi.sinif_8}
            download
          >
            8. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi.sinif_9}
            download
          >
            9. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi.sinif_10}
            download
          >
            10. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi.sinif_11}
            download
          >
            11. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi.sinif_12}
            download
          >
            12. Sınıflar
          </a>
        </div>
      )}
      {!params.bolgeFinaliSonucListesi && <MaterialBackDrop />}
    </div>
  );

  const bolgeFinaliSonuclari = (
    <div className={classNames.itemBody}>
      <Typography size="smaller" style={{ textAlign: "right" }}>
        02.04.2024
      </Typography>

      <p>Bölge Finalleri Sınavına ait sonuçlar aşağıda açıklanmıştır.</p>
      <p>
        Koşullarda belirtildiği üzere her sınıf için her bölgeden ilk 3 takım
        Türkiye Finalleri'ne katılmaya hak kazanmıştır. Ayrıca bölge içinde ilk
        3'e girememiş olan takımlardan Türkiye geneli sıralaması en yüksek
        olanlar listeye dahil edilerek toplam sayı her sınıf için 50'ye
        tamamlanmıştır.
      </p>
      <p>
        Puanı ve ek soru puanı aynı olan takımlar kendi aralarında sıralanırken
        ilgili takımların İl Finalleri Sınavı'ndaki Türkiye dereceleri dikkate
        alınmıştır.
      </p>

      <p>
        Katılım/başarı belgeleri ve sosyal etkinlik formaları sisteme
        yüklenmiştir.
      </p>
      <p>
        Türkiye Finalleri Sınavı takvimde belirtilen tarihlerde Ankara'da yazılı
        olarak yapılacaktır. Sınava 1 hafta kala sistem üzerinde feragat butonu
        aktif hale gelecektir. Sınava katılamayacak adaylar dilerse feragat
        butonunu kullanarak hakkını İl Finalleri derecesi en yüksek olan
        sıradaki adaya devredebilir.
      </p>

      {params.bolgeFinaliSonucListesi && (
        <div
          style={{
            fontSize: "1.2rem",
            display: "flex",
            flexDirection: "column",
            width: 150,
          }}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={params.bolgeFinaliSonucListesi.sinif_1}
            download
          >
            1. Sınıflar
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={params.bolgeFinaliSonucListesi.sinif_2}
            download
          >
            2. Sınıflar
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={params.bolgeFinaliSonucListesi.sinif_3}
            download
          >
            3. Sınıflar
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={params.bolgeFinaliSonucListesi.sinif_4}
            download
          >
            4. Sınıflar
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={params.bolgeFinaliSonucListesi.sinif_5}
            download
          >
            5. Sınıflar
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={params.bolgeFinaliSonucListesi.sinif_6}
            download
          >
            6. Sınıflar
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={params.bolgeFinaliSonucListesi.sinif_7}
            download
          >
            7. Sınıflar
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={params.bolgeFinaliSonucListesi.sinif_8}
            download
          >
            8. Sınıflar
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={params.bolgeFinaliSonucListesi.sinif_9}
            download
          >
            9. Sınıflar
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={params.bolgeFinaliSonucListesi.sinif_10}
            download
          >
            10. Sınıflar
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={params.bolgeFinaliSonucListesi.sinif_11}
            download
          >
            11. Sınıflar
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={params.bolgeFinaliSonucListesi.sinif_12}
            download
          >
            12. Sınıflar
          </a>
        </div>
      )}
      {!params.bolgeFinaliSonucListesi && <MaterialBackDrop />}
    </div>
  );

  const ilFinaliSonuclari = (
    <div className={classNames.itemBody}>
      <Typography size="smaller" style={{ textAlign: "right" }}>
        28.03.2025
      </Typography>
      <p>
        İl Finalleri Sınavına ait sonuçlar açıklanmıştır. İl Finalleri Sınavı
        sonunda her okulun en iyi takımı belirlenir ve bu puan ilgili okulun o
        sınıftaki Okul İl Puanı olarak kabul edilir. İl genelinde derecelendirme
        yapılırken bu puan dikkate alınır. Takım puanlarında eşitlik olması
        durumunda takım üyelerinin bir önceki aşamada elde ettikleri dereceler
        dikkate alınarak takımlar arasında sıralama yapılır.
      </p>

      {/* <p>
        Puanı ve ek puanı eşit olan öğrenciler kendi aralarında sıralanırken
        -koşullarda belirtildiği üzere- sınavı erken tamamlayan yarışmacı üst
        sırada yer alacak şekilde düzenleme yapılmıştır.
      </p> */}
      <p>
        İl Finalleri Sınavı sonunda her ilde sınıf bazında ilk 2 sırayı alan
        takımlar Bölge Finalleri Sınavına katılmaya hak kazanırlar. Bu takımlara
        ek olarak sınıf bazında sıralamalar dikkate alınır ve sınava katılacak
        takım sayıları her bölgede her sınıf için 50'ye tamamlanır. Bölge
        Finalleri Sınavına her okul her sınıfta en fazla bir takımla (sıralamada
        en üstte bulunan) katılabilir.
      </p>
      <p>
        Öğrencilere ve öğretmenlere verilecek olan PDF katılım/başarı belgeleri
       ilerleyen haftalarda sisteme yüklenecektir.
      </p>
      {/* <p>
        Bölge Finalleri Sınavı'na katılım hakkı kazandığı halde bu hakkından
        feragat etmek isteyen yarışamacılar, 11-15 Mart tarihleri arasında
        sisteme girerek FERAGAT butonuna basabilirler. Bu durumda katılım hakkı
        sıradaki yarışmacıya devredilir.
      </p> */}
      {/* <p>
        İtirazlarla alakalı olarak, 1. soruda verilen hayvan isimleri ve
        sayıları dikkate alınarak bir çözüm beklenmiştir. Görseldeki hayvan
        sayıları dikkate alınmadan bulunan cevaplar kabul edilmemiştir.
      </p>
      <p>
        Sözcük bulmaca sorularında ise bulunacak sözcüğün TDK sözlükte yer
        alması gerekmektedir (Aksi belirtilmediği sürece). BÜT, TESTEM vb.
        kelimeler TDK sözlükte bulunmadığı için reddedilmiştir.
      </p>
      <p>
        Fenerler sorusunda gelen itirazların tamamı reddedilmiştir. Bu soruya
        itiraz eden yarışmacıların çoğu dikkatsizlik sonucu soru metninde
        verilen 'Fenerleri birbirine ve gemilere komşu olmayacak şekilde
        yerleştiriniz...' ifadesini gözden kaçırmış ve fenerlerin bazılarını
        komşu olacak biçimde yerleştirmiştir.
      </p> */}

      <hr></hr>
      <h5>OKUL PUANLARI</h5>
      {params.ilFinaliSonucListesi && (
        <div
          style={{
            fontSize: "1.2rem",
            display: "flex",
            flexDirection: "column",
            width: 150,
          }}
        >
          <a
            target="_blank"
            href={params.ilFinaliSonucListesi.sinif_1}
            download
          >
            1. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.ilFinaliSonucListesi.sinif_2}
            download
          >
            2. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.ilFinaliSonucListesi.sinif_3}
            download
          >
            3. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.ilFinaliSonucListesi.sinif_4}
            download
          >
            4. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.ilFinaliSonucListesi.sinif_5}
            download
          >
            5. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.ilFinaliSonucListesi.sinif_6}
            download
          >
            6. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.ilFinaliSonucListesi.sinif_7}
            download
          >
            7. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.ilFinaliSonucListesi.sinif_8}
            download
          >
            8. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.ilFinaliSonucListesi.sinif_9}
            download
          >
            9. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.ilFinaliSonucListesi.sinif_10}
            download
          >
            10. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.ilFinaliSonucListesi.sinif_11}
            download
          >
            11. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.ilFinaliSonucListesi.sinif_12}
            download
          >
            12. Sınıflar
          </a>
        </div>
      )}
      {!params.ilFinaliSonucListesi && <MaterialBackDrop />}
    </div>
  );

  const trFinaliCevaplari = (
    <div className={classNames.itemBody}>
      <Typography size="smaller" style={{ textAlign: "right" }}>
        05.05.2024
      </Typography>
      <p>
        Türkiye Finalleri Sınavına ait cevap anahtarları açıklanmıştır. Sorular
        ve cevap anahtarı için son itiraz tarihi 8 Mayıs 2024 Çarşamba saat
        23:59'dur.
      </p>

      {/* <p>Bölge Finallerine katılacak okullar için asil-yedek öğrenci bilgileri gün içerisinde her yarışmacının profil sayfasında ayrıca yayınlanacaktır.</p> */}

      {params.trFinaliSonucListesi && (
        <div
          style={{
            fontSize: "1.2rem",
            display: "flex",
            flexDirection: "column",
            width: 150,
          }}
        >
          <a
            target="_blank"
            href={params.trFinaliSonucListesi["1_2_cevap_anahtari"]}
            download
          >
            1-2. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi["3_4_cevap_anahtari"]}
            download
          >
            3-4. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi["ortaokullar_cevap_anahtari"]}
            download
          >
            5-6-7-8. Sınıflar
          </a>
          <a
            target="_blank"
            href={params.trFinaliSonucListesi["liseler_cevap_anahtari"]}
            download
          >
            9-10-11-12. Sınıflar
          </a>
        </div>
      )}
      {!params.trFinaliSonucListesi && <MaterialBackDrop />}
    </div>
  );

  const blFinaliYonerge = (
    <div className={classNames.itemBody}>
      <Typography size="smaller" style={{ textAlign: "right" }}>
        15.03.2024
      </Typography>

      <p>
        {`Değerli Yarışmacımız,

1- Bölge Finalleri Sınavı, 16 Mart 2024, Cumartesi günü Saat 13.00’te başlayacak ve 14.30'da kapatılacaktır. (Gerekli durumlarda 1-2 dakika uzatma yapılabilir.)

2- Yarışmacıların sınavdan 1 saat önce sisteme giriş yapmasını ve “BÖLGE FİNALLERİ SINAVI" butonuna basarak sınav ekranında beklemesini tavsiye ederiz.

3- Bir okulda Bölge Finalleri'ne katılmaya hak kazanan aynı sınıftaki yarışmacılar (yahut feragat eden adaylar yerine katılacak olanlar) okul takımını oluşturmaktadır. (Her sınıf için ayrı takım) 

4- Takım üyeleri aynı sınav ekranını göreceklerdir. Üyeler takım adına cevap girişi yapabilir. Üyelerden herhangi birinin sorulara cevap vermesi yeterlidir. 

5- Takım üyelerinden herhangi biri cevap değişikliği yaptığında diğer takım arkadaşlarının ekranında cevabı değiştiren kişinin ismi ve hangi soruda değişiklik yaptığı yazacaktır.

6- Herhangi bir üye tarafından son girilen cevap takım adına verilmiş cevap olarak kabul edilip bu cevap üzerinden değerlendirme yapılacaktır.

7- Sınav esnasında takım üyeleri aynı ortamda tek bir bilgisayar/hesap üzerinden sınava katılabilir. 

8- Aynı ortamda bulunamayacak yarışmacılar, kendi hesapları ile oturum açıp farklı yerlerden de aynı sınava katılabilir, sorulara cevap verebilir. Bu durumda üyelerin sınav süresince birbirleriyle iletişim kurabilecekleri bir yöntem belirlemeleri uygun olacaktır. 

9- Özellikle son 10 dakika tüm takım üyelerinin cevaplarda görüş birliği sağlamaları açısından önemlidir.

10- Sınavda 10 asıl soru ve 10 ek soru yer alacaktır. İl Finalleri Sınavı'nda olduğu gibi, asıl değerlendirme ilk 10 soruya göre yapılacak, aynı puanı alan takımlar kendi aralarında sıralanırken ek soru puanlarına bakılacaktır.


UYARI: Sorumlu öğretmen kaydı olmayan okulların iletişimin aksamaması ve takımların takip edilebilmesi açısından en yakın zamanda öğretmen kaydı yapmalarını tavsiye ederiz. 

Tüm yarışmacılara başarılar dileriz.
Saygılarımızla,
Türkiye Zeka Vakfı`}
      </p>
    </div>
  );

  const trFinaliYonerge = (
    <div className={classNames.itemBody}>
      <Typography size="smaller" style={{ textAlign: "right" }}>
        26.04.2024
      </Typography>

      <p>Değerli Finalistimiz,</p>
      <p>
        Türkiye Okullar Arası Zeka Oyunları Şampiyona’sında göstermiş olduğunuz
        başarıdan dolayı kutluyoruz.
      </p>

      <p>
        Türkiye Finalleri Sınavı, 4 Mayıs Cumartesi günü Ankara’da Orta Doğu
        Teknik Üniversitesinde Kafererya Binasınında (ODTÜ Rektörlük binasının
        yanı) yapılacaktır.
      </p>
      <p>
        ODTÜ kapı girişlerinde kimlik kontrolü yapılmaktadır. Bu nedenle sınav
        saatinden en az 1 saat önce ODTÜ giriş kapılarında olmanızı öneririz.
        Ayrıca, ihtiyaç dâhilinde ve gerekli görüldüğü durumlarda araçların
        bagaj kontrolü de yapılabilmektedir.
      </p>
      <h5 style={{ fontWeight: "bold", marginTop: 15 }}>
        SINAVA GELİRKEN DİKKAT ETMENİZ GEREKEN KONULAR
      </h5>
      <p>Sınava kalem ve silgi getirmek katılımcının sorumluluğundadır. </p>
      <p>
        Katılımcılar sınav salonlarına sınavdan 45 dakika önce alınmaya
        başlayacak ve sınavın ilk ve son 10 dakikası sınav salonlarına giriş ve
        çıkış olmayacaktır.
      </p>
      <p>Sınavda ara verilmeyecektir.</p>
      <p>
        Sınava girmeden önce kimlik kontrolü yapılacaktır. Yarışmacının yanında{" "}
        <b>nüfus cüzdanı</b>nı bulundurması zorunludur.{" "}
      </p>
      <p>Sınav sonrasında bir devam etkinliği olmayacaktır. </p>
      <p>
        Yarışmacıların sınava gireceği masa/sıra numarasını içeren bilgi notu
        sınava 1 hafta kala her yarışmacının profil sayfasında yayınlanacaktır.
      </p>
      <h5 style={{ fontWeight: "bold", marginTop: 15 }}>
        SINAV NEREDE DÜZENLENECEK?
      </h5>
      <p>
        Final Sınavı, 4 Mayıs Cumartesi günü Ankara’da, Orta Doğu Teknik
        Üniversitesi Kafeterya Binasında gerçekleştirilecektir. (ODTÜ Rektörlük
        Binasının yanı)
      </p>
      <p>
        Adres: ODTÜ Kafeterya Müdürlüğü, Üniversiteler, Dumlupınar Blv. No:1,
        06800 Çankaya/Ankara
      </p>
      <p>
        Google maps linkine{" "}
        <a
          href="https://maps.app.goo.gl/93aLyVCt66seBEBV9"
          target="_blank"
          rel="noopener noreferrer"
        >
          buradan
        </a>{" "}
        ulaşabilirsiniz.
      </p>

      <h5 style={{ fontWeight: "bold", marginTop: 15 }}>
        SINAV SAAT KAÇTA OLACAK?
      </h5>
      <p>
        İlkokul, Ortaokul ve Lise kategorileri aynı gün 3 oturum şeklinde
        aşağıdaki saat aralıklarında yapılacaktır.
      </p>
      <ul>
        <li>İlkokullar (1-2-3-4. sınıflar): 10.30-11.30 </li>
        <li>Ortaokullar (5-6-7-8. sınıflar): 13.30-14.30</li>
        <li>Liseler (Hazırlık/9-10-11-12. sınıflar): 16.30-17.30</li>
      </ul>

      <h5 style={{ fontWeight: "bold", marginTop: 15 }}>
        SINAV ALANINA NASIL ULAŞIRIM?
      </h5>

      <p>Kızılay’dan ODTÜ’ye ulaşımı aşağıdaki şekillerde sağlayabilirsiniz.</p>
      <ol>
        <li>
          Güvenpark dolmuş duraklarındaki Kızılay-ODTÜ dolmuşlarını
          kullanabilirsiniz. Dolmuş ile kampüse giriş yaptıktan sonra ODTÜ ana
          giriş kapısından sınav alanına tahminen 10-15 dakika yürüyerek
          ulaşabilirsiniz.{" "}
        </li>
        <li>
          ODTÜ’ye Güvenpark’tan kalkan 132, 411 ve 482 numaralı
          Kızılay-ODTÜ özel halk otobüsü ile ulaşabilirsiniz. ODTÜ içerisinde
          KKM (Kültür Kongre Merkezi) durağında inebilirsiniz. (Sınav alanına
          tahminen 3-5 dakika yürüyerek ulaşabilirsiniz.){" "}
        </li>
        <li>
          Kızılay’dan Koru metrosuna binip ODTÜ durağında inebilirsiniz. ODTÜ
          ana giriş kapısından sınav alanına tahminen 10-15 dakika yürüyerek
          ulaşabilirsiniz.
        </li>
      </ol>
      <p>Sıhhiye ve Ulus’tan Ulaşım:</p>
      <ol>
        <li>
          Ulus İstanbul Caddesi, Gençlik Parkı karşısındaki dolmuş
          duraklarından, ODTÜ-Ulus dolmuşları ile de ODTÜ kampüsüne
          ulaşım sağlayabilirsiniz. Dolmuş ile kampüse giriş yaptıktan sonra
          ODTÜ ana giriş kapısından 10-15 dakika yürüyerek sınav alanına
          ulaşabilirsiniz.
        </li>
      </ol>
      <h5 style={{ fontWeight: "bold", marginTop: 15 }}>
        KONAKLAMA YAPILABİLECEK YERLER
      </h5>
      <p>
        Sınava şehir dışından gelecek olan katılımcılarımız, varsa konaklama
        ihtiyaçlarını tercihleri doğrultusunda aşağıdaki alternatifleri
        değerlendirebilirler. Ek olarak 100. Yıl, Balgat, Çukurambar
        çevresindeki alternatifler de değerlendirilebilir. Ulaşım ve konaklama
        ücretleri katılımcıların kendileri tarafından karşılanacaktır.
      </p>
      <p>
        <b>ODTÜ MİSAFİRHANESİ:</b>
        <br />
        Adres: ODTÜ Üniversiteler Mah. Dumlupınar Blv. No:1 06800 Çankaya Ankara
        <br />
        Telefon: (0312) 210 28 90 -91
        <br />
        <a
          href="https://stm.metu.edu.tr/tr/odtu-misafirhane-iletisim"
          target="_blank"
          rel="noopener noreferrer"
        >
          Detaylı bilgi bağlantısı.
        </a>
      </p>

      <p>
        <b>ODTÜ AYSEL SABUNCU YAŞAM MERKEZİ:</b>
        <br />
        Adres: ODTÜ Üniversiteler Mah.Dumlupınar Blv.No:1 06800
        <br />
        Telefon: (0312)  210 6560
        <br />
        <a
          href="http://stm.metu.edu.tr/asym-iletisim"
          target="_blank"
          rel="noopener noreferrer"
        >
          Detaylı bilgi bağlantısı.
        </a>
      </p>

      <p>
        <b>BAŞKENT ÖĞRETMENEVİ:</b>
        <br />
        Adres: Emniyet Mahallesi, Gazi Hastanesi Karşısı, 06560 Beşevler /
        Yenimahalle/Yenimahalle/Ankara
        <br />
        Telefon: (0312) 212 96 40
        <br />
        <a
          href="https://baskentogretmenevi.com.tr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Detaylı bilgi bağlantısı.
        </a>
      </p>

      <p>
        <b>CLASS OTEL:</b>
        <br />
        Adres: Remzi Oğuz Arık, Bestekâr Cd. No:64, 06680 Çankaya/Ankara
        <br />
        Telefon: (0312) 466 18 50
        <br />
        <a
          href="http://www.classhotel.com.tr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Detaylı bilgi bağlantısı.
        </a>
      </p>

      <p>
        <b>DAFNE OTEL:</b>
        <br />
        Adres: Balgat, 71545 Sokak Karakusunlar Mahallesi, 06530
        <br />
        Telefon: (0312) 285 98 88
        <br />
        <a
          href="http://www.dafnehotel.com.tr/index.asp"
          target="_blank"
          rel="noopener noreferrer"
        >
          Detaylı bilgi bağlantısı.
        </a>
      </p>

      <p>
        <b>SONNO OTEL:</b>
        <br />
        Adres: Remzi Oğuz Arık, Tunus Cd. No:52, 06680 Çankaya/Ankara
        <br />
        Telefon: (0312)466 27 27
        <br />
        <a
          href="http://www.sonnohotel.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Detaylı bilgi bağlantısı.
        </a>
      </p>
      <h5 style={{ fontWeight: "bold", marginTop: 15 }}>
        SINAV ALANINA TAKSİ ÇAĞIRMAK İSTERSENİZ:
      </h5>

      <p>
        ODTÜ içinden taksi kullanımı istendiğinde; 0 312 219 51 51 nolu telefon
        ile taksi çağırılabilir.
      </p>
      <p>
        Bitirirken; bu uzun bilgilendirme yazısını okuduğunuz için teşekkür
        ederiz. Eğer yanıtlanmadığını düşündüğünüz ve ihtiyaç duyduğunuz başka
        bir sorunuz varsa bizimle info@tzv.org.tr adresinden iletişime
        geçebilirsiniz.
      </p>
      <p>Tüm Öğrencilerimize Başarılar Dileriz!</p>
      <p>
        Saygılarımızla,
        <br />
        Türkiye Zeka Vakfı
      </p>
    </div>
  );

  // return (
  //   <div style={{ marginTop: "120px", textAlign: "center" }}>
  //     {/* {trFinaliSonuclari} */}
  //     {/* {bolgeFinaliSonuclari} */}
  //     {blFinaliYonerge}
  //     {ilFinaliSonuclari}
  //     {/* {pilotTestAnnouncement} */}
  //   </div>
  // );

  return (
    <div className={classNames.steps_container}>
      <Typography color="orange" size="bigger">
        Duyurular
      </Typography>
      <div style={{ padding: 10 }}>
        {/* <Collapsable
          title={<StepTitle left="Türkiye Finalleri Sınavı Sonuçları" />}
          body={trFinaliSonuclari}
          onToggle={(show) => toggleCollapse(show, 2)}
          showing={activeIndex === 2}
        />
        <Collapsable
          title={
            <StepTitle left="Türkiye Finalleri Sınavı Cevap Anahtarları" />
          }
          body={trFinaliCevaplari}
          onToggle={(show) => toggleCollapse(show, 3)}
          showing={activeIndex === 3}
        /> */}
        {/* <Collapsable
          title={<StepTitle left="Türkiye Finalleri Sınavı Yönergesi" />}
          body={trFinaliYonerge}
          onToggle={(show) => toggleCollapse(show, 3)}
          showing={activeIndex === 3}
        /> */}
        {/* <Collapsable
          title={<StepTitle left="Bölge Finalleri Sınavı Sonuçları" />}
          body={bolgeFinaliSonuclari}
          onToggle={(show) => toggleCollapse(show, 4)}
          showing={activeIndex === 4}
        /> */}
        <Collapsable
          title={<StepTitle left="İl Finalleri Sınav Sonuçları" />}
          body={ilFinaliSonuclari}
          onToggle={(show) => toggleCollapse(show, 5)}
          showing={activeIndex === 5}
        />
        <ScrollToTop />
      </div>
    </div>
  );
};

export default DetailedUpdates;

const StepTitle = ({ left, right }) => (
  <Typography
    style={{ color: "#10273C" }}
    font="merel"
    weight="bold"
    size="small"
  >
    {left}
    {right && <span style={{ marginLeft: 3 }}>:</span>}
    <span className="orange"> {right}</span>
  </Typography>
);
