import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import classNames from "./manage.module.css";
import { disclaim, getFilteredUsers } from "../../store/actions/generic";
import { GET_FILTERED_USERS } from "../../store/actionTypes";
import Loading from "../../components/ui/loading/loading";
import UpdateUser from "./updateUser/updateUser.js";
import AnnouncementModal from "../../components/ui/announcementModal/announcementModal";
import { SeasonEnding } from "../../containers/manage/seasonEnding";
import { BfSection } from "./components/bfSection.jsx";
import { AnnouncementSection } from "./components/announcementSection.jsx";
import Button from "../../components/ui/button";
import { IfSection } from "./components/ifSection.jsx";
import { TbSection } from "./components/tbSection.jsx";
import { paidUserInfoBox } from "./components/paidUserInfoBox.jsx";
import { PaymentInstructionsBox } from "./components/paymentInstructionsBox.jsx";
import { UserProfileSummaryBox } from "./components/userProfileSummaryBox.jsx";
import { TfSection } from "./components/tfSection.jsx";
import Typography from "../../components/ui/typography/index.js";
import { EXAM_SEASONS } from "../../common/examData.js";
import sha256 from "crypto-js/sha256";
import Hex from "crypto-js/enc-hex";
import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PhotoUpload from "./components/PhotoUpload.jsx";
const Manage = () => {
  const competitorVisitedManagementPageOnThisSession =
    window.sessionStorage.getItem("USER_VISITED_THE_MANAGEMENT_PAGE");

  const [showEditModal, setShowEditModal] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [isWaitingDisclaimResult, setIsWaitingDisclaimResult] = useState(false);
  const [coachNameOfStudent, setCoachNameOfStudent] = useState("");

  const user = useSelector((state) => state.auth && state.auth.user);
  const users_of_this_school = useSelector(
    (state) => state.generic.filtered_users
  );
  useEffect(() => {
    if (user) {
      if (!user.class_id && user.role === 0) return null;
      fetchFilteredUsers();
      const coachList = [...user.all_coaches_of_this_school].filter(
        (c) => c.class_id === user.class_id
      );
      const coachNameOfThisClass = coachList && coachList[0]?.name;

      setCoachNameOfStudent(coachNameOfThisClass);
      window.sessionStorage.setItem("USER_VISITED_THE_MANAGEMENT_PAGE", true);
    }
  }, [user]);

  useEffect(() => {
    async function reArrangeUserDataStructure() {
      if (user) {
        let studentList_ = [];

        if (user.role === 1) {
          // console.log("users_of_this_school", users_of_this_school);
          studentList_ = users_of_this_school
            .filter((a) => {
              let coachClasses = user.all_coaches_of_this_school
                .filter((c) => c.id === user.id)
                .map((c) => c.class_id);

              let isThisStudentBelongsToCoach = coachClasses.includes(
                a.class_id
              );

              return a.role == 0 && isThisStudentBelongsToCoach;
            })
            .sort((a, b) => {
              // const x = a.product_title ? a.product_title : "";
              // const y = b.product_title ? b.product_title : "";
              // return x + a.name > y + b.name;
              return a.class_id - b.class_id;
              // return a.name> b.name;
            });
        } else if (user.role === 0) {
          studentList_ = users_of_this_school
            .filter((a) => {
              return a.role === 0 && a.class_id === user.class_id;
            })
            .sort((a, b) => {
              // const x = a.product_title ? a.product_title : "";
              // const y = b.product_title ? b.product_title : "";
              // return x + a.name > y + b.name;
              return a.class_id - b.class_id;
              // return a.name> b.name;
            });
        }

        studentList_
          .sort((s1, s2) => {
            const order1 =
              s1.exam_results.find((r) => r.season === "2024_if")
                ?.order_in_country ?? 9999999;
            const order2 =
              s2.exam_results.find((r) => r.season === "2024_if")
                ?.order_in_country ?? 9999999;

            return order1 - order2;
          })
          .sort((s1, s2) => s1.class_id - s2.class_id);

        for (let i = 0; i < users_of_this_school.length; i++) {
          const student = users_of_this_school[i];

          const percentileOfStudent = student.exam_results.find(
            (r) => r.season === "2024_if"
          )?.percentile_in_country;
          const conclusionOfStudent = student.exam_results.find(
            (r) => r.season === "2024_if"
          )?.conclusion;

          const percentileOfStudentBF = student.exam_results.find(
            (r) => r.season === "2024_bf"
          )?.percentile_in_country;
          const conclusionOfStudentBF = student.exam_results.find(
            (r) => r.season === "2024_bf"
          )?.conclusion;

          const tfResult =
            student.exam_results.find((r) => r.season === "2024_tf") ?? {};

          if (
            [2, 4].includes(conclusionOfStudent) ||
            ([1, 3].includes(conclusionOfStudent) && !conclusionOfStudentBF) // if sonrası, bf öncesi bu satırı commentlemek gerek. BF'den sonra sınava katılması gerektiği halde katılmayanlar için bu satır lazım.
          )
            //TODO: öğrenci bf katılması gerektiği halde katılmadıysa bu sebf'yi alacak.
            student.willUserTakeIF_SEBF = true;

          if (percentileOfStudent <= 0.3) {
            student.willUserTakeIFBasariBelgesi = true;
          } else if (percentileOfStudent > 0.3)
            student.willUserTakeIFKatilimBelgesi = true;

          if (
            // bf'de elenenlere veya if'de başarılı olup da bf'de feragat edenlere bf sebf verilecek
            conclusionOfStudentBF === 2 ||
            (conclusionOfStudentBF === 4 && conclusionOfStudent === 1) ||
            ([1, 3].includes(conclusionOfStudentBF) &&
              !tfResult.order_in_country) // bf'de başarılı olup da tf'ye katılmayanlara sebf verilecek
          )
            student.willUserTakeBF_SEBF = true;

          if (
            conclusionOfStudentBF === 1 ||
            (conclusionOfStudentBF === 4 && conclusionOfStudent === 1)
          ) {
            // bf'de başarılı olan veya if'de başarılı olup da bf'de feragat edenlere bf başarı verilecek
            student.willUserTakeBFBasariBelgesi = true;
          } else if ([2].includes(conclusionOfStudentBF))
            // 3, yani feragat eden yerine tf'ye katılacak olanlara belge vermiyoruz. bf'ye katılmadı çünkü
            student.willUserTakeBFKatilimBelgesi = true;

          if (tfResult.order_in_country && tfResult.order_in_country <= 3) {
            student.willUserTakeTFBasariBelgesi = true;
          } else if (
            tfResult.order_in_country &&
            tfResult.order_in_country > 3
          ) {
            student.willUserTakeTFKatilimBelgesi = true;
          }

          if (tfResult.order_in_country) {
            student.willUserTakeTF_SEBF = true;
          }
        }

        setStudentList(studentList_);
      }
    }

    reArrangeUserDataStructure();
  }, [users_of_this_school]);

  const dispatch = useDispatch();

  const fetchFilteredUsers = () => {
    if (user && user.role < 2) {
      let filter = { school_id: user.school_id };
      dispatch(getFilteredUsers(filter));

      // pullResutlsBySchoolId(user.school_id, setParam);
    }
  };

  const isFetchingUsers = useSelector(
    (state) => state.loading[GET_FILTERED_USERS]
  );

  const products = useSelector(() => (user && user.applied_products) || []);
  const didStudentPay = user && user.role === 0 && products[0];
  if (!user) return <Redirect to="/" />;
  // if (!products[0] && user.role === 0) return <Redirect to="/satinal" />;
  if (user.role === 2) return <Redirect to="/admin" />;

  const onDisclaimButtonPress = async (season) => {
    const confirm1 = window.confirm(
      "Hakkınızdan feragat etmek istediğinize emin misiniz?"
    );
    let confirm2 = false;

    if (confirm1) {
      confirm2 = window.confirm("Bu işlem geri alınamayacak!");
    }

    if (confirm1 && confirm2) {
      setIsWaitingDisclaimResult(true);
      dispatch(disclaim({ season })).then((res) => {
        if (res) {
          alert(res.message);
        } else alert("HATA: Lütfen sayfayı yenileyiniz. (Hata kodu: 87647781)");

        fetchFilteredUsers();
        setIsWaitingDisclaimResult(false);
        window.location.reload(true);
      });
    }
  };

  const onUserProfileEditClick = () => {
    // alert(
    //   "8 Ekim 2022 tarihinde kayıtların açılması ile birlikte bilgilerinizi güncelleyebilirsiniz."
    // );
    setShowEditModal(true);
  };

  const announcementModal = false && (
    <AnnouncementModal
      title="Ücretsiz Deneme Sınavı"
      body="Deneme sınavı 18 Ocak Cumartesi saat 13.00'te şampiyona profil sayfasında çevrim içi olarak gerçekleştirilecektir. Kayıt olan herkes sınava katılabilir. Sınav süresi 45 dakikadır."
    />
  );

  const userProfileSummaryBox = UserProfileSummaryBox({
    user,
    onUserProfileEditClick,
    coachNameOfStudent,
  });

  const tbSection = TbSection({
    user,
    didStudentPay,
    studentList,
    users_of_this_school,
    onDisclaimButtonPress,
  });

  const ifSection = IfSection({
    user,
    didStudentPay,
    studentList,
    users_of_this_school,
    onDisclaimButtonPress,
  });

  const bfSection = BfSection({
    user,
    studentList,
    users_of_this_school,
  });

  const tfSection = TfSection({
    user,
    studentList,
    users_of_this_school,
    coachNameOfStudent,
    onDisclaimButtonPress,
  });

  const editUserModal = showEditModal && (
    <UpdateUser
      onClose={(res) => {
        setShowEditModal(false);
        if (res.refetchFilteredUsers) {
          fetchFilteredUsers();
        }
      }}
      selectedUser={user}
      fetchFilteredUsers={() => fetchFilteredUsers()}
    />
  );
  const generatePersonalExamLink = ({ usr, buttonName, season, size }) => {
    const { class_id, id, name, role } = usr;
    // if (role !== 0 || !class_id) return null;

    let [examId, examQuestionsId] = EXAM_SEASONS[season].exam_ids[class_id];

    const id_hash = sha256(id.toString() + "tuz").toString(Hex);
    const user_identifier = id + id_hash;

    const regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#'"]/gi;
    let userNameForUrl = name.trim().replaceAll(regex_symbols, ""); // Özel karakterleri silelim, url'de hata çıkmasına sebep olur.
    userNameForUrl = userNameForUrl.replaceAll(" ", "+");

    const link =
      "https://exam-3125b.web.app/linkedExam/" +
      examId +
      "&" +
      examQuestionsId +
      "&" +
      user_identifier +
      "&" +
      userNameForUrl;

    const smallStyle = {
      fontSize: 12,
    };

    const normalStyle = {
      fontSize: 20,
      marginRight: 20,
      padding: 5,
      marginTop: 12,
    };

    return (
      <a href={link} target="_blank">
        <Button style={size === "small" ? smallStyle : normalStyle}>
          {buttonName ?? "İl Finalleri Sınavı"}
        </Button>

        <LinearProgress color="secondary" />
      </a>
    );
  };

  const renderDenemeSinavi = () => {
    if (user.role === 0 && user.class_id !== null) {
      return (
        <div className={"card " + classNames.left_step}>
          <Typography size="small" color="white" font="tadao" weight="normal">
            ÜCRETSİZ DENEME SINAVI
          </Typography>
          <Typography
            style={{
              fontStyle: "italic",
              color: "orange",
              marginTop: 10,
              fontSize: "16px",
            }}
          >
            {
              "Deneme Sınavı, sınav ekranlarının tanıtımı amacıyla yapılacaktır ve sonuç açıklanmayacaktır. Bu sınav, Takım Belirleme Sınavı'na kadar aktif kalacaktır. Takvimde belirtilen tarihte, 8 Şubat saat 13.00'te başlayacak olan Deneme Sınavı'na katılabilirsiniz. Ancak, sınava tam olarak 13.00'te girme zorunluluğu bulunmamaktadır; Takım Belirleme Sınavı'na kadar herhangi bir zamanda sınava katılabilirsiniz."
            }
          </Typography>
          {generatePersonalExamLink({
            usr: user,
            buttonName: "Deneme Sınavına Katıl",
            season: "dnm_2025",
          })}
          {/* <Typography
            style={{
              fontStyle: "italic",
              color: "orange",
              marginTop: 10,
              fontSize: "16px",
            }}
          >
            {
              "Deneme amaçlı bu sınavda geçmiş yıllarda sorulan sorulardan yararlanılmıştır. Doğru cevaplar sayfada yayınlanacak ancak herhangi bir puanlama ve sıralama yapılmayacaktır."
            }
          </Typography> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          ></div>
        </div>
      );
    } else if (user.role === 0 && !user.class_id) {
      return (
        <div
          className={"card " + classNames.left_step}
          style={{ color: "white", fontSize: "1.3rem" }}
        >
          Ücretsiz deneme sınavına katılabilmek için lütfen okul ve sınıf
          bilgilerinizi güncelleyiniz.
        </div>
      );
    } else return null;
  };
  const turnuva = [
    {
      rank: 1,
      name: "Ahmet Tuna Aktepe",
      award:
        "Emrehan Halıcı’nın IPP (International Puzzle Party) için tasarladığı oyun ",
    },
    {
      rank: 2,
      name: "Oğuzhan Şimşek",
      award:
        "Emrehan Halıcı’nın IPP (International Puzzle Party) için tasarladığı oyun",
    },
    {
      rank: 3,
      name: "Duru Loya Emrebak",
      award:
        "Emrehan Halıcı’nın IPP (International Puzzle Party) için tasarladığı oyun",
    },
  ];
  const renderGriCeviz = () => {
    if (user.role === 0 && user.class_id !== null) {
      return (
        <div className={"card " + classNames.left_step}>
          <Typography size="small" color="white" font="tadao" weight="normal">
            Ücretsiz GriCeviz Turnuvası Sonuçları
          </Typography>
          <Typography
            style={{
              fontStyle: "italic",
              color: "orange",
              marginTop: 10,
              fontSize: "16px",
            }}
          >
            {
              "GriCeviz turnuvası sonucunda dereceye girenler aşağıdaki şekildedir. Kazanan kişiler ile sistemde bulunan iletişim bilgileri üzerinden iletişime geçilecektir."
            }
          </Typography>

          <TableContainer component={Paper} style={{ marginTop: 15 }}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "orange" }}>
                  <TableCell style={{ color: "white", fontWeight: "bold" }}>
                    Sıralama
                  </TableCell>
                  <TableCell style={{ color: "white", fontWeight: "bold" }}>
                    İsim Soyisim
                  </TableCell>
                  <TableCell style={{ color: "white", fontWeight: "bold" }}>
                    Ödül
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {turnuva.map((item) => (
                  <TableRow key={item.rank}>
                    <TableCell>{item.rank}.</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.award}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    } else if (user.role === 0 && !user.class_id) {
      return <></>;
    } else return null;
  };
  return (
    <div className={classNames.manage}>
      {/* {announcementModal} */}
      <div>
        {(isFetchingUsers || isWaitingDisclaimResult) && <Loading />}
        {userProfileSummaryBox}

        {/* {user.role === 0 && !didStudentPay && (
          <PaymentInstructionsBox user={user} />
        )} */}
        {/* {renderGriCeviz()} */}
        {/* {renderDenemeSinavi()} */}
        {tbSection}
        {ifSection}
        {/* //Sınav zamanı açılsın */}
        {/* {user.role === 0 && didStudentPay && <PhotoUpload />} */}
        {/* {bfSection}
        {tfSection} */}
        {/* {AnnouncementSection()} */}
        {/* //İl finalleri sınavından sonra açılmalı */}
        {didStudentPay && paidUserInfoBox({ user })}
        <SeasonEnding />
        {editUserModal}
      </div>
    </div>
  );
};

export default Manage;
