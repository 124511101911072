import React from "react";
import classNames from "../manage.module.css";
import { personalExamLink, teamExamLink } from "./generateLinks";
import Button from "../../../components/ui/button";

export const paidUserInfoBox = ({ user }) => {
  return (
    <div
      style={{ color: "white", fontSize: "1.3rem" }}
      className={"card " + classNames.left_step}
    >
      {user.class_id && (
        <div className={classNames.old_exams}>
          {user.class_id >= 3 && (
            <h3 style={{ marginBottom: 20, fontSize: 22 }}>
              Geçen Yıla Ait Sorular
            </h3>
          )}

          {personalExamLink({
            usr: user,
            buttonName: "2023-24 İl Finalleri Sınavı",
            season: "if_2324",
          })}
          {teamExamLink({
            usr: { ...user, school_id: user.school_id + 99999 }, // school_id'yi değiştirmezsek bu kullanıcı bu sınıf-okul'dan geçen sene sınava girmiş üst devrenin sınav ekranını ve responselarını görüyor.
            season: "bf_2324",
            buttonName: "2023-24 Bölge Finalleri Sınavı",
          })}
          {user.class_id > 0 && user.class_id < 3 && (
            <a
              href={
                "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Ftr_finali_sorulari%2F1-2.s%C4%B1n%C4%B1f.pdf?alt=media&token=de66a6d2-24a5-46f5-bb99-01c4e6916688"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={{ fontSize: 20, marginRight: 20, padding: 5 }}>
                {"2024 Türkiye Finalleri Sınavı"}
              </Button>
            </a>
          )}
          {user.class_id > 2 && user.class_id < 5 && (
            <a
              href={
                "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Ftr_finali_sorulari%2F3-4.s%C4%B1n%C4%B1f.pdf?alt=media&token=340f94ba-727f-405f-a0ae-31bdfbde9544"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={{ fontSize: 20, marginRight: 20, padding: 5 }}>
                {"2024 Türkiye Finalleri Sınavı"}
              </Button>
            </a>
          )}
          {user.class_id > 4 && user.class_id < 9 && (
            <a
              href={
                "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Ftr_finali_sorulari%2F5-6-7-8.s%C4%B1n%C4%B1f.pdf?alt=media&token=7be162d7-c57f-4f2d-b392-e496ae694ff6"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={{ fontSize: 20, marginRight: 20, padding: 5 }}>
                {"2024 Türkiye Finalleri Sınavı"}
              </Button>
            </a>
          )}
          {user.class_id > 8 && (
            <a
              href={
                "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Ftr_finali_sorulari%2F9-10-11-12.s%C4%B1n%C4%B1f.pdf?alt=media&token=64dad60e-50d8-4fa4-b88a-5c40a47031e0"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={{ fontSize: 20, marginRight: 20, padding: 5 }}>
                {"2024 Türkiye Finalleri Sınavı"}
              </Button>
            </a>
          )}
        </div>
      )}
    </div>
  );
};
