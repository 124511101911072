export const EXAM_SEASONS = {
  if_2122: {
    season_name: "if_2122", // intellisense için
    exam_ids: {
      1: ["if2022_sinif_3_xG5M3I5hBVO0RQPPoRsM", "tzvif3wlvShrn56g94I4WMT3tM"],
      2: ["if2022_sinif_3_xG5M3I5hBVO0RQPPoRsM", "tzvif3wlvShrn56g94I4WMT3tM"],
      3: ["if2022_sinif_3_xG5M3I5hBVO0RQPPoRsM", "tzvif3wlvShrn56g94I4WMT3tM"],
      4: ["if2022_sinif_4_mqVDCReKn4rngPxOjH5O", "tzvif45z81290jmLFdgzCpqsuP"],
      5: ["if2022_sinif_5_EG9PnOLmsBTqx0svBhnK", "tzvif5M4NDPudbeTxIZ7HkJE26"],
      6: ["if2022_sinif_6_hnFVSI7Xn25w9aDCCbAy", "tzvif6pxOEI2BAm8iWNsIIhpJ2"],
      7: ["if2022_sinif_7_FBG04GFN1pdiF67BKALr", "tzvif7yJvjql8U5SvqzspeuNCS"],
      8: ["if2022_sinif_8_AUbD4HbANQu6E7BcUFVJ", "tzvif8lNDWcAtaWJj2ycSIB7ru"],
      9: ["if2022_sinif_9_M0Y3kySdUYK947jSij0h", "tzvif9zD0NPkVMaGRUT8OEKkXB"],
      10: [
        "if2022_sinif_10_oOkXkvGgW5sploMQWKdR",
        "tzvif10EIXUUM7HvTmWYakb1qmM",
      ],
      11: [
        "if2022_sinif_11_UZZU3uwAKlbEovcQUzsU",
        "tzvif11zFDHcELCVnUApU5HNSQv",
      ],
      12: [
        "if2022_sinif_12_khiU0y5b66iNaz1cDGW4",
        "tzvif12wFZeOjjs5lTOirYQH2Gf",
      ],
    },
  },

  bf_2122: {
    season_name: "bf_2122", // intellisense için
    exam_ids: {
      1: ["bf2022_sinif_3_oLvJb9OzpUdeKnKi8SqM", "tzvbf3WuTlD5qWk2yHZsqDodPh"],
      2: ["bf2022_sinif_3_oLvJb9OzpUdeKnKi8SqM", "tzvbf3WuTlD5qWk2yHZsqDodPh"],
      3: ["bf2022_sinif_3_oLvJb9OzpUdeKnKi8SqM", "tzvbf3WuTlD5qWk2yHZsqDodPh"],
      4: ["bf2022_sinif_4_gBabGafqhDmWl39w0ySL", "tzvbf4R7zcLzXKgS5O76BXAGwL"],
      5: ["bf2022_sinif_5_XyMxsRbcMkq1xw5TaDJl", "tzvbf5L4adju6Aljq6TI9WXJ9N"],
      6: ["bf2022_sinif_6_Epp2v1vBFZHSkuCQel3g", "tzvbf68azYY0UksPfN0JESkgFU"],
      7: ["bf2022_sinif_7_hIXOIIOsdCCJ2nIsbY9s", "tzvbf7K5sv4nRkssRzrPzEBech"],
      8: ["bf2022_sinif_8_phslrVVyauH1a6uP2G0y", "tzvbf8BWvn4SSEeMQ79BRQjlSH"],
      9: ["bf2022_sinif_9_EiernFEL4WxT2kCgreVq", "tzvbf9EDrzhyO14XQ489p0FD0Y"],
      10: [
        "bf2022_sinif_10_k02EQlNCrwpdHh37PI3H",
        "tzvbf10jM2Za3pn9SaEG24KO6bh",
      ],
      11: [
        "bf2022_sinif_11_5417r1T14vatdJfM98To",
        "tzvbf11ExEUjfYQGeyhd7il7d4c",
      ],
      12: [
        "bf2022_sinif_12_bwA4vxQQ0oKd6qe7AhBe",
        "tzvbf12iLpWKrlWybekZzwtNl1u",
      ],
    },
  },

  tf_2122: {
    season_name: "tf_2122", // intellisense için
    exam_ids: {
      1: [
        "tf2022_sinif_3_1wyNnkdGuX8VglfX7vC2",
        "tf2022_sinif_3_questions_HXuxFQYK2DXk6VG2wvWf",
      ],
      2: [
        "tf2022_sinif_3_1wyNnkdGuX8VglfX7vC2",
        "tf2022_sinif_3_questions_HXuxFQYK2DXk6VG2wvWf",
      ],
      3: [
        "tf2022_sinif_3_1wyNnkdGuX8VglfX7vC2",
        "tf2022_sinif_3_questions_HXuxFQYK2DXk6VG2wvWf",
      ],
      4: [
        "tf2022_sinif_4_3Lzi2sVr8INIzRKrLhjr",
        "tf2022_sinif_4_questions_CAUOCdF5ocOSNkdjBxfv",
      ],
      5: [
        "tf2022_sinif_5_41NdpPwlVYu3hgvfg2pT",
        "tf2022_sinif_5_questions_RLnmbSWTVTLQOXg0wqmJ",
      ],
      6: [
        "tf2022_sinif_6_6kuJUctEvbocMBO9QxFl",
        "tf2022_sinif_6_questions_nWT842U0GLQZB4olf3sm",
      ],
      7: [
        "tf2022_sinif_7_LMQHNEIiQrexdktXdMXM",
        "tf2022_sinif_7_questions_Qr7Aos9W8cekl2lhgWZV",
      ],
      8: [
        "tf2022_sinif_8_NqbeJgbJMjxhWAQJP3sC",
        "tf2022_sinif_8_questions_2e9WmQAOkIz6RE4d3s81",
      ],
      9: [
        "tf2022_sinif_9_csSp4KkfRUhtWEYJ8h9i",
        "tf2022_sinif_9_questions_F9Gsj3g9UAlkWgnZKGMo",
      ],
      10: [
        "tf2022_sinif_10_knTvPXGOhCutkynfZz2z",
        "tf2022_sinif_10_questions_v6Zjs8UzmnPyO3oBj0aE",
      ],
      11: [
        "tf2022_sinif_11_mPH03pNaDsa4G46JoODD",
        "tf2022_sinif_11_questions_PexcpbiLe1v98iOh9SCr",
      ],
      12: [
        "tf2022_sinif_12_piG1TtvXHkkotzyY00qG",
        "tf2022_sinif_12_questions_R6b5FBcWhKnk2FQvYhpV",
      ],
    },
  },
  if_2223: {
    season_name: "if_2223",
    exam_ids: {
      1: [
        "if2023_sinif_1_TqIpXBnJ7Ge6rrK9j9y1",
        "if2023_sinif_1_eq_CI3FTjzd1YQsWmuXICV1",
      ],
      2: [
        "if2023_sinif_2_JtCrSwyaKI4t3RBL1sjX",
        "if2023_sinif_2_eq_dNw1Rs5QqHEuj4zRHsoL",
      ],
      3: [
        "if2023_sinif_3_br3gGEX1kFG6q03fk7oj",
        "if2023_sinif_3_eq_Ne4IVnekA91XWGGqxpIj",
      ],
      4: [
        "if2023_sinif_4_MZfJhMDVIATzcwxLMvaz",
        "if2023_sinif_4_eq_rVH0XuSRsE58lgSWW5NK",
      ],
      5: [
        "if2023_sinif_5_X34cAwvqUmrJh6Ro1amv",
        "if2023_sinif_5_eq_rztv27Ylx1NWdqPIwjlv",
      ],
      6: [
        "if2023_sinif_6_uZ3be60SKimADnPyBMPy",
        "if2023_sinif_6_eq_kgyG2ubflISfbqqteAKY",
      ],
      7: [
        "if2023_sinif_7_yrplFfAemqSz6HL5rXZf",
        "if2023_sinif_7_eq_nYlZN8PUee5u5dZYtYuq",
      ],
      8: [
        "if2023_sinif_8_bLC5sZ5moDaPguskLiny",
        "if2023_sinif_8_eq_0PEVoL9pqNNHUVe2Jhtu",
      ],
      9: [
        "if2023_sinif_9_pI4Y3OJjgPIjQXbo3Zx8",
        "if2023_sinif_9_eq_j7FiISXE50LY59CQlzb5",
      ],
      10: [
        "if2023_sinif_10_zj8HkZZLeKsdnBabIq7P",
        "if2023_sinif_10_eq_N8uvSe8Yz5B06LN56dg1",
      ],
      11: [
        "if2023_sinif_11_2ZuQIHhWtZnet0SdfYlv",
        "if2023_sinif_11_eq_GccRBqYzyac7Zdu4WHTV",
      ],
      12: [
        "if2023_sinif_12_jSH4p8neXAomQLomiyip",
        "if2023_sinif_12_eq_rZa6zZ96WKof9VThD9Pi",
      ],
    },
  },

  bf_2223: {
    season_name: "bf_2223", // intellisense için
    exam_ids: {
      1: [
        "bf2023_sinif_1_azr8o81GO1PHo1hsdy4L",
        "bf2023_sinif_1_eq_slwyllTwaVJVZDBNjmL2",
      ],
      2: [
        "bf2023_sinif_2_s6bifxLXBpKSf4QzOhgk",
        "bf2023_sinif_2_eq_jvFnO58OYkl0retydbIC",
      ],
      3: [
        "bf2023_sinif_3_5ZqPsEcyGIPWNHbxzECo",
        "bf2023_sinif_3_eq_QpFkjPSpFOkABdvk2C5h",
      ],
      4: [
        "bf2023_sinif_4_OmQOkoh4EpwJp4OunMMK",
        "bf2023_sinif_4_eq_m3wbdlasrxAA6rvyM6Zs",
      ],
      5: [
        "bf2023_sinif_5_GhjJCAQAeKV8R46aQQ0p",
        "bf2023_sinif_5_eq_jZRjgu0NUdF0qIkDJ0vs",
      ],
      6: [
        "bf2023_sinif_6_5xt0prF6R3wOSCa8sp99",
        "bf2023_sinif_6_eq_hIFhhc15zE4rUXdsKphH",
      ],
      7: [
        "bf2023_sinif_7_cSq77TPqTS9UQcGtRGwL",
        "bf2023_sinif_7_eq_n1of7t8HtP46YEvAL7Uq",
      ],
      8: [
        "bf2023_sinif_8_dPCLXKopjxDcYrovQCia",
        "bf2023_sinif_8_eq_s5ZHwKelbeApyhsVXoac",
      ],
      9: [
        "bf2023_sinif_9_knqacAbBtWjiCgQ516TP",
        "bf2023_sinif_9_eq_cHxcCd1t1UzwCK5MqoWf",
      ],
      10: [
        "bf2023_sinif_10_0IwuxgPAm94UMc1kxKs5",
        "bf2023_sinif_10_eq_gSAGzNG3olq7VPZpauks",
      ],
      11: [
        "bf2023_sinif_11_Ya9gK9iwb6v8Xm6mjfqB",
        "bf2023_sinif_11_eq_PMF6S1rmGxPZarnt72Gx",
      ],
      12: [
        "bf2023_sinif_12_8KR0gNUoXjjdtfOblfLq",
        "bf2023_sinif_12_eq_7mvrfeOmmtdQjmljxXbK",
      ],
    },
  },

  dnm_2024: {
    season_name: "dnm_2024",
    exam_ids: {
      1: [
        "dnm2024_sinif_1_HCW3NpxP9ehhIbOiM53i",
        "dnm2024_sinif_1_eq_JPak4OSjEe4jrO8tsPd0",
      ],
      2: [
        "dnm2024_sinif_2_3F51iWSyclvX8vSCynNj",
        "dnm2024_sinif_2_eq_rZcWKy2NPvTaZs42NxRr",
      ],
      3: [
        "dnm2024_sinif_3_wup8T5CpQ3S72UvjIOD4",
        "dnm2024_sinif_3_eq_xypbUIKTBH55yBt6incD",
      ],
      4: [
        "dnm2024_sinif_4_YexTGssteH1EuBStC8oi",
        "dnm2024_sinif_4_eq_SQqspJt5D1X5UCKRq08H",
      ],
      5: [
        "dnm2024_sinif_5_x8JnRbGBkWw1dwwlJRGo",
        "dnm2024_sinif_5_eq_aJvxDCa4CMLJA6hAPEk3",
      ],
      6: [
        "dnm2024_sinif_6_cF0UBBcAelBfhPE66Xnm",
        "dnm2024_sinif_6_eq_u5hKqPkRhyPWp2LzLwyX",
      ],
      7: [
        "dnm2024_sinif_7_eFwLVXFEDgPl1t8Hs7mU",
        "dnm2024_sinif_7_eq_Yh5kSGiHCbw12KhJuP7f",
      ],
      8: [
        "dnm2024_sinif_8_rHE8Bvj9hbmypQcXBuyX",
        "dnm2024_sinif_8_eq_YuU7QGO2z1mjkbqCTocW",
      ],
      9: [
        "dnm2024_sinif_9_w0h0LgOqesikrJtdeapG",
        "dnm2024_sinif_9_eq_3DK1dJGyT0UUEKmW9lKi",
      ],
      10: [
        "dnm2024_sinif_10_stVcs9vd4QxCWgIs638g",
        "dnm2024_sinif_10_eq_uvB43lHxO2OO72cnGJJn",
      ],
      11: [
        "dnm2024_sinif_11_8ROPtFbTdTUrEOJquT7y",
        "dnm2024_sinif_11_eq_ZDputsgkbO6C3eL2uupF",
      ],
      12: [
        "dnm2024_sinif_12_Ss8V7sMHSLhWXob2Ts6z",
        "dnm2024_sinif_12_eq_sQq0EU8OLcOtC9v9NW5V",
      ],
    },
  },

  if_2324: {
    season_name: "if_2324",
    exam_ids: {
      1: [
        "if2024_sinif_1_qYvPcb68hvB5170bXzN3",
        "if2024_sinif_1_eq_cMN5w718AbIyxs0Q7Qoi",
      ],
      2: [
        "if2024_sinif_2_XeSSe2IfOFAhtBbhiBmF",
        "if2024_sinif_2_eq_HDfWjHruOHBlV7mTA1af",
      ],
      3: [
        "if2024_sinif_3_63jYwvCts3TqM484IFoJ",
        "if2024_sinif_3_eq_5EQZgHI94F8Mz2PsmVc1",
      ],
      4: [
        "if2024_sinif_4_3FtlLjqQz82uRITewryH",
        "if2024_sinif_4_eq_JW53DytDJo9vmLDfSacF",
      ],
      5: [
        "if2024_sinif_5_k62pi3OFTwDTFbMhgY5Y",
        "if2024_sinif_5_eq_N0MdA0xIqVBQvZ3Ir0lM",
      ],
      6: [
        "if2024_sinif_6_k4zwxsC88bADUkiU6Lov",
        "if2024_sinif_6_eq_QEdCWX9sP45ZTwfHqe0b",
      ],
      7: [
        "if2024_sinif_7_0BHWiIUTYo08PQBdmZWv",
        "if2024_sinif_7_eq_pVsbqXFmFyG3oQwAY6XB",
      ],
      8: [
        "if2024_sinif_8_kC66v4Xu7ZKMneYGhSq4",
        "if2024_sinif_8_eq_uHsNF6u6gLIe9LE5x4IC",
      ],
      9: [
        "if2024_sinif_9_0EExd0abQpxn7Wks7yaz",
        "if2024_sinif_9_eq_XIlVfZWPWgqJMApGSEgQ",
      ],
      10: [
        "if2024_sinif_10_eWbFldGuOv8LikQUwCeB",
        "if2024_sinif_10_eq_4DkQJF8rhNRAq6GFZVk0",
      ],
      11: [
        "if2024_sinif_11_XTIytVWjOFURQy0lqZEm",
        "if2024_sinif_11_eq_ZmSR455RRMJELWwA0hu6",
      ],
      12: [
        "if2024_sinif_12_wbMjwGSmsBrpWkBtBrfm",
        "if2024_sinif_12_eq_0cbYX942ZHhUjm6bCwV8",
      ],
    },
  },

  bf_2324: {
    season_name: "bf_2324",
    exam_ids: {
      1: [
        "bf2024_sinif_1_YCbnSYjrm8XQP92hTL1c",
        "bf2024_sinif_1_eq_iZxRQnoOOxoTa4i7kD5d",
      ],
      2: [
        "bf2024_sinif_2_eoeKOUokXRO8ISxxcqzj",
        "bf2024_sinif_2_eq_QuUrziWeCIi3LMfOvI7E",
      ],
      3: [
        "bf2024_sinif_3_wSFDtZN2ThGqtn63AAMs",
        "bf2024_sinif_3_eq_dtUhMnmlQG8vkIivdL9y",
      ],
      4: [
        "bf2024_sinif_4_DXrEnfD15zClmH5uec4Q",
        "bf2024_sinif_4_eq_TZ5421GejWWi6y83yxi8",
      ],
      5: [
        "bf2024_sinif_5_bi3RNw79pXSKJi5rs0lp",
        "bf2024_sinif_5_eq_XRAZIU7IKnVFRdnPVcBV",
      ],
      6: [
        "bf2024_sinif_6_0FHdYIpUdN0s8fzSRG6Y",
        "bf2024_sinif_6_eq_BUIPhwjLlplc0Urr8kmC",
      ],
      7: [
        "bf2024_sinif_7_Oo62sxTd5m7ONdShUAvc",
        "bf2024_sinif_7_eq_J7POfTesJuNVhIyWWDeM",
      ],
      8: [
        "bf2024_sinif_8_HJWKCryomeqTrR0Fs4CM",
        "bf2024_sinif_8_eq_TKhCN0EySN2iUWhFVlA0",
      ],
      9: [
        "bf2024_sinif_9_Klv12CLv1UgoX2dQqgey",
        "bf2024_sinif_9_eq_ZOJfzsVYAdH5fiVBDU5q",
      ],
      10: [
        "bf2024_sinif_10_gOwGbtty8a7h3MAR4BZD",
        "bf2024_sinif_10_eq_okbgt5Nzdna1ryeHIknL",
      ],
      11: [
        "bf2024_sinif_11_JhfIJi1UDih9XjWiavIz",
        "bf2024_sinif_11_eq_nibTK65czq8EKsflKdGw",
      ],
      12: [
        "bf2024_sinif_12_EslKm3SejDrQS8BGMSql",
        "bf2024_sinif_12_eq_a6Bs0RdVZUcRYyAxPLuj",
      ],
    },
  },

  //2025 deneme sınavı linkleri
  dnm_2025: {
    season_name: "dnm_2025",
    exam_ids: {
      1: [
        "dnm2025_sinif_1_Fzr8oq2kO1lHJ1hsdmDx",
        "dnm2025_sinif_1_eq_1lwejlEva2JbNDaNJQLr",
      ],
      2: [
        "dnm2025_sinif_2_L1bijxLsdpDSf3QzOHQp",
        "dnm2025_sinif_2_eq_QGEn34HOykl0qetYbDi4",
      ],
      3: [
        "dnm2025_sinif_3_3cQPBeZyEIpWBfbXEcl",
        "dnm2025_sinif_3_eq_beFkrMspwOhAmdvv3Cbm",
      ],
      4: [
        "dnm2025_sinif_4_YmQ0koH4EpWJp4zuNikQ",
        "dnm2025_sinif_4_eq_lLQb3LaXrMaS4rvTm3ZC",
      ],
      5: [
        "dnm2025_sinif_5_JhjICAFAeKv7r29aqd02",
        "dnm2025_sinif_5_eq_pZRjGuJNrdfGQIKBJ3HM",
      ],
      6: [
        "dnm2025_sinif_6_1CtGpRF3R1WosBa8ApQ6",
        "dnm2025_sinif_6_eq_qiewhcW5zB3r4cYsUpbU",
      ],
      7: [
        "dnm2025_sinif_7_VsQ0cTPqtS6uQcgTBAfL",
        "dnm2025_sinif_7_eq_g1Wf4ynBtN22u3MnAe1N",
      ],
      8: [
        "dnm2025_sinif_8_pADLlzYPjzDCYRoLQXgk",
        "dnm2025_sinif_8_eq_YRxHwJElBeBtyhfVc0Qq",
      ],
      9: [
        "dnm2025_sinif_9_mWVaCaUnRWeixGq018t3",
        "dnm2025_sinif_9_eq_cj3cCh431nZwFbK5BqoO",
      ],
      10: [
        "dnm2025_sinif_10_BIWyPxDgBC4TMk2LlK2N",
        "dnm2025_sinif_10_eq_HcAqzNGtO3qnV3ZsaUbw",
      ],
      11: [
        "dnm2025_sinif_11_NB4qF2iJAFv4Xm2MjfQk",
        "dnm2025_sinif_11_eq_bfFHr34mQxPcSVy7GWQp",
      ],
      12: [
        "dnm2025_sinif_12_2lq2gNYoXfjFCFlBSfLX",
        "dnm2025_sinif_12_eq_5mRrWeBMWtdYjBlJDvbH",
      ],
    },
  },

  //Burası takım belirleme sınavı linkleri
  tb_2425: {
    season_name: "tb_2425",
    exam_ids: {
      1: [
        "tb2025_sinif_1_Szr8o91kO1lHP1hsdN4X",
        "tb2025_sinif_1_eq_PlwyjlRwawJVNDBNjnL6",
      ],
      2: [
        "tb2025_sinif_2_K4bijxLXdpKSf4Qz0hgP",
        "tb2025_sinif_2_eq_kvEn257OYkl0qetydbIQ",
      ],
      3: [
        "tb2025_sinif_3_2cqPsEZyeIPWNfbxzECS",
        "tb2025_sinif_3_eq_FpFkjMSpFOkaBdvD2C5s",
      ],
      4: [
        "tb2025_sinif_4_UmqOkoh4EpVJp4zuniMp",
        "tb2025_sinif_4_eq_N3QbdLasrxaB6rvym6ZX",
      ],
      5: [
        "tb2025_sinif_5_NhjiCAfAeKV7R26aQQ0h",
        "tb2025_sinif_5_eq_PZrjgujNRdFgqIkAJ0vV",
      ],
      6: [
        "tb2025_sinif_6_4CtHprF6R3wOsCa8sp63",
        "tb2025_sinif_6_eq_qIFHhc95zE2rUcdsKpiP",
      ],
      7: [
        "tb2025_sinif_7_WSq07TPqTS6UQcGtBGwN",
        "tb2025_sinif_7_eq_c1of2t9HtP4uYEvBL7U2",
      ],
      8: [
        "tb2025_sinif_8_BPdLXzopjxDcYroLQCiP",
        "tb2025_sinif_8_eq_24ZHwJelBeApyhSVcoaX",
      ],
      9: [
        "tb2025_sinif_9_JnWacAUBRWjiCgq013TP",
        "tb2025_sinif_9_eq_ZjxcCd1t1kzwCbK5mqoVH",
      ],
      10: [
        "tb2025_sinif_10_8IwuxDgAmw4UMk1klKs1",
        "tb2025_sinif_10_eq_gDAgzNG3OLq7VpZpaukE",
      ],
      11: [
        "tb2025_sinif_11_YA9GK2iJb6v8Xm6Njfqj",
        "tb2025_sinif_11_eq_vMf6S3rmQxPZarnt75qx",
      ],
      12: [
        "tb2025_sinif_12_4lW0gNUoXFjdRfOblfL5",
        "tb2025_sinif_12_eq_3mvrfeWMmtdqjmljxvbL",
      ],
    },
  },
   //il finalleri sınavı linkleri
   if_2425: {
    season_name: "if_2425",
    exam_ids: {
      1: [
        "if2025_sinif_1_Pzr8p9SkO1ljP1hsdm8z",
        "if2025_sinif_1_eq_clwyLlRwBQJVNsBNjmL7",
      ],
      2: [
        "if2025_sinif_2_q8AGjxlXdpKdf4Qz0hgÇ",
        "if2025_sinif_2_eq_KvEC27XOYkllqewydvIX",
      ],
      3: [
        "if2025_sinif_3_UcqwsEZYeIPqNfbxzbAa",
        "if2025_sinif_3_eq_JpDkFMSpFYkaBdvDxC9a",
      ],
      4: [
        "if2025_sinif_4_XDqOKoh6EpVJp8zunLMV",
        "if2025_sinif_4_eq_K3QXdLAsRxaB3rvLm6mC",
      ],
      5: [
        "if2025_sinif_5_MhliCbfArKV7R26awq0F",
        "if2025_sinif_5_eq_şZejgjxNRDFGqIkAJ2vW",
      ],
      6: [
        "if2025_sinif_6_vCtHPrF6q3wOsCa8sp69",
        "if2025_sinif_6_eq_FlFghx97zE2wUcDsKpLC",
      ],
      7: [
        "if2025_sinif_7_eaSq57TPqTg6UQbGtBqwv",
        "if2025_sinif_7_eq_V2osot4Hth4uzxvBş8is",
      ],
      8: [
        "if2025_sinif_8_cudLlzopKxDctroLQCiP",
        "if2025_sinif_8_eq_QwxHrJeKxeALyFSvcOaB",
      ],
      9: [
        "if2025_sinif_9_JVWacAUvRWjlCgq013ET",
        "if2025_sinif_9_eq_XjBcNd2t1kZwCBK5ÇqoXF",
      ],
      10: [
        "if2025_sinif_10_qIwuXDgbmw8UMk1HlKsh",
        "if2025_sinif_10_eq_FgafZNk7O8d7VpZpaUkc",
      ],
      11: [
        "if2025_sinif_11_zA6GK2iJb6y8Xn6Njfqf",
        "if2025_sinif_11_eq_Ynf8feRmQxCZaRnt78qL",
      ],
      12: [
        "if2025_sinif_12_hfW0gmUoXFddefObLfLş",
        "if2025_sinif_12_eq_E8vrLeQCmTdqjmljxFbŞ",
      ],
    },
  },
};
