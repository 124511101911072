import { EXAM_SEASONS } from "../../../common/examData";
import sha256 from "crypto-js/sha256";
import Hex from "crypto-js/enc-hex";
import React from "react";
import Button from "../../../components/ui/button";
import { Link } from "react-router-dom";
import classNames from "../manage.module.css";
import Typography from "../../../components/ui/typography";

export const personalExamLink = ({ usr, buttonName, season, size }) => {
  const { class_id, id, name, role } = usr;
  if (role !== 0 || !class_id) return null;

  let [examId, examQuestionsId] = EXAM_SEASONS[season].exam_ids[class_id];

  const id_hash = sha256(id.toString() + "tuz").toString(Hex);
  const user_identifier = id + id_hash;

  const regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#'"]/gi;
  let userNameForUrl = name.trim().replaceAll(regex_symbols, ""); // Özel karakterleri silelim, url'de hata çıkmasına sebep olur.
  userNameForUrl = userNameForUrl.replaceAll(" ", "+");

  const link =
    "https://sinav.tzv.org.tr/linkedExam/" +
    examId +
    "&" +
    examQuestionsId +
    "&" +
    user_identifier +
    "&" +
    userNameForUrl;

  const smallStyle = {
    fontSize: 12,
  };

  const normalStyle = {
    fontSize: 20,
    marginRight: 20,
    padding: 5,
  };

  return (
    <a href={link} target="_blank">
      <Button style={size === "small" ? smallStyle : normalStyle}>
        {buttonName ?? "İl Finalleri Sınavı"}
      </Button>

      {/* <LinearProgress color="secondary" /> */}
    </a>
  );
};

export const teamExamLink = ({ usr, buttonName, season, size, teamId }) => {
  const { school_id, class_id, id, role, name } = usr;

  if (role !== 0 || !class_id) return null;

  let [examId, examQuestionsId] = EXAM_SEASONS[season].exam_ids[class_id];

  const school_class = school_id + "_" + class_id;

  const regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#'"]/gi;
  let userNameForUrl = name.trim().replaceAll(regex_symbols, ""); // Özel karakterleri silelim, url'de hata çıkmasına sebep olur.
  userNameForUrl = userNameForUrl.replaceAll(" ", "+");

  const teamOrSchoolId = teamId ?? school_class;

  const id_hash = sha256(
    examId + examQuestionsId + teamOrSchoolId + userNameForUrl
  )
    .toString(Hex)
    .substring(0, 10);
  // const user_identifier = school_class + "_" + id_hash;

  const link =
    "https://sinav.tzv.org.tr/linkedExam/" +
    // "http://localhost:3001/linkedExam/" +
    examId +
    "&" +
    examQuestionsId +
    "&" +
    teamOrSchoolId +
    "&" +
    userNameForUrl +
    "&" +
    id_hash;

  const smallStyle = {
    fontSize: 12,
  };

  const normalStyle = {
    fontSize: 20,
    marginRight: 20,
    padding: 5,
    marginLeft: 0,
  };

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Button style={size === "small" ? smallStyle : normalStyle}>
        {buttonName ?? "Sınav Linki"}
      </Button>

      {/* <LinearProgress color="secondary" /> */}
    </a>
  );
};
export const detailedUpdatesLink = (buttonName) => {
  return (
    <Link
      to="/updates"
      style={{
        textDecoration: "none",
        backgroundColor: "cornsilk",
        fontSize: "20px",
        minWidth: 150,
        height: 40,
        padding: 5,
        textAlign: "center",
        borderRadius: 5,
        marginRight: 20,
        color: "black",
        marginTop: 10,
      }}
    >
      {buttonName}
    </Link>
  );
};

export const whatsappInfoLink = (
  <div className={classNames.step_one_title}>
    <Typography
      font="merel"
      weight="normal"
      size="small"
      color="orange"
      style={{ fontSize: 22 }}
    >
      <span title="Şampiyonaya ait tüm duyurular artık bu bağlantı üzerindeki kanaldan yapılacaktır.">
        {"Whatsapp Kanalı: "}
      </span>
      <span className="white">
        {" "}
        Whatsapp duyuru kanalımıza katılmak için
        <a
          href="https://whatsapp.com/channel/0029VaIl6730Qeak6SX1yh1M"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          tıklayınız.{" "}
        </a>
        Anlık bildirimleri nasıl açacağınızı görmek için
        <a
          href="https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fwhatsap_bildirim_ayarlari.gif?alt=media&token=2781fbcc-269e-4ede-8bc0-b18d08dc570a"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          tıklayınız.{" "}
        </a>
      </span>
    </Typography>
  </div>
);