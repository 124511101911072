import React, { useState, useEffect } from "react";
import Typography from "../../../components/ui/typography";
import {
  detailedUpdatesLink,
  personalExamLink,
  teamExamLink,
} from "./generateLinks";
import classNames from "../manage.module.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Redirect } from "react-router-dom";
import { GoogleFormsLink } from "./objectionBox";
import { IF_RESULT_TEXTS } from "../../../common/constants";
import { Link } from "react-scroll";

export const IfSection = ({
  user,
  didStudentPay,
  studentList,
  users_of_this_school,
}) => {
  const [params, setParams] = useState({
    anyCoachUserAttendedToIF: false,
    anySchoolUserAttendedToIF: false,
    anyStudentOfThisCoachTakesIFsuccessDoc: false,
    anyStudentOfThisSchoolTakesIFsuccessDoc: false,
  });
  const [isLoading, setIsLoading] = useState(true);

  const [redirectPage, setRedirectPage] = useState("");
  const refLoginUsersTeamId = React.useRef(1);

  useEffect(() => {
    setIsLoading(true);
    // Exam results geldiğinde öğrenci listesi üzerinden 1 tur geçip bu okuldan hangi sınavlara katılım olduğunu bulalım:
    const schoolResults = studentList; // 1-3
    const allStudentsOfThisSchool = users_of_this_school; // 5

    if (user?.role === 0 && users_of_this_school) {
      // Bu öğrenci sadece kendi takımındaki elemanları görecek, bu nedenle önce öğrencinin takım id'sini bulalım:
      let myTeamId = 1;
      for (let i = 0; i < users_of_this_school.length; i++) {
        if (users_of_this_school[i].id === user.id) {
          myTeamId = users_of_this_school[i].exam_results.find(
            (er) => er.season === "2025_tb"
          )?.team_id;
          refLoginUsersTeamId.current = myTeamId;
          break;
        }
      }
    }

    if (user?.role === 1 && allStudentsOfThisSchool) {
      let anyCoachUserAttendedToIF = false;
      let anySchoolUserAttendedToIF = false;
      let anyStudentOfThisCoachTakesIFsuccessDoc = false;
      let anyStudentOfThisSchoolTakesIFsuccessDoc = false;

      for (let i = 0; i < schoolResults.length; i++) {
        // sadece bu öğretmenin öğrencilerini kontrol eder:
        if (schoolResults[i].willUserTakeIFBasariBelgesi === true) {
          anyCoachUserAttendedToIF = true;
          anyStudentOfThisCoachTakesIFsuccessDoc = true;
        }

        if (schoolResults[i].willUserTakeIFKatilimBelgesi === true) {
          anyCoachUserAttendedToIF = true;
        }
      }

      for (let x = 0; x < allStudentsOfThisSchool.length; x++) {
        if (allStudentsOfThisSchool[x].willUserTakeIFBasariBelgesi === true) {
          anySchoolUserAttendedToIF = true;
          anyStudentOfThisSchoolTakesIFsuccessDoc = true;
        }

        if (allStudentsOfThisSchool[x].willUserTakeIFKatilimBelgesi === true) {
          anySchoolUserAttendedToIF = true;
        }
      }

      setParams({
        ...params,
        anyCoachUserAttendedToIF,
        anySchoolUserAttendedToIF,
        anyStudentOfThisCoachTakesIFsuccessDoc,
        anyStudentOfThisSchoolTakesIFsuccessDoc,
      });
    }
    setIsLoading(false);
  }, [studentList, users_of_this_school]);

  if (!didStudentPay && user?.role === 0) return null;

  const studentTableTB = (
    <Box sx={{ overflow: "auto", marginTop: 20 }}>
      <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
        <TableContainer component={Paper}>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "40px",
                backgroundColor: "#fff",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <div
                  className="loading-spinner"
                  style={{
                    border: "4px solid #f3f3f3",
                    borderTop: "4px solid #3498db",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    animation: "spin 1s linear infinite",
                    margin: "0 auto 20px auto",
                  }}
                />
                <Typography style={{ color: "#666" }}>
                  Veriler yükleniyor...
                </Typography>
              </div>
            </Box>
          ) : (
            <Table aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {[
                    { name: "ID", align: "center" },
                    { name: "Ad-Soyad", align: "left" },
                    { name: "E-Posta", align: "left", hideForThisRole: 0 },
                    // { name: "Sınıf", align: "center" },
                    // { name: "Ücret Durumu", align: "center", hideForThisRole: 0 },
                    // { name: "Seç", align: "center", hideForThisRole: 0 },
                    // { name: "Puanı", align: "center" },
                    // { name: "TR Sırası", align: "center" },
                    /* { name: "Takım", align: "center", hideForThisRole: 0 },*/

                    // { name: "Katılım/Başarı Belgesi", align: "center" },
                    //  { name: "Sosyal Etkinlik Formu", align: "center" },
                    // { name: "Belge İndir", align: "center" },
                    // { name: "Sosyal Etkinlik Formu", align: "center" },
                    // { name: "Bölge Finalleri Sonucu", align: "center" },
                    // { name: "Türkiye Finallerine Katılım", align: "center" },
                    { name: "İl Finali Puanı", align: "center" },
                    {
                      name: "Bölge Finaline Katılım Durumu",
                      align: "center",
                    },
                    {
                      name: "Sınav Linki",
                      align: "center",
                      hideForThisRole: 0,
                    },
                  ].map(
                    (row) =>
                      row.hideForThisRole !== user.role && (
                        <TableCell
                          key={row.name + "_user_list_column_name"}
                          style={{ fontWeight: "bold" }}
                          align={row.align}
                        >
                          {row.name}
                        </TableCell>
                      )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(() => {
                  // Benzersiz team_id'leri topla
                  const uniqueTeamIds = new Set();
                  studentList.forEach((row) => {
                    const tb_2025_result =
                      row?.exam_results.find((r) => r.season === "2025_tb") ??
                      {};
                    if (tb_2025_result.team_id) {
                      uniqueTeamIds.add(tb_2025_result.team_id);
                    }
                  });
                  let lastTeamId = null;

                  // Öğrenci listesini team_id'ye göre sırala
                  const sortedStudentList = [...studentList].sort((a, b) => {
                    const aResult =
                      a?.exam_results?.find((r) => r.season === "2025_tb") ??
                      {};
                    const bResult =
                      b?.exam_results?.find((r) => r.season === "2025_tb") ??
                      {};

                    const aTeamId = aResult.team_id || "ZZZZZZ";
                    const bTeamId = bResult.team_id || "ZZZZZZ";

                    // Takım ID'lerini parçalara ayır
                    const [aClass = "Z", aSchool = "Z", aTeam = "Z"] =
                      aTeamId.split("-");
                    const [bClass = "Z", bSchool = "Z", bTeam = "Z"] =
                      bTeamId.split("-");

                    // Önce sınıfa göre sırala
                    if (aClass !== bClass) {
                      return aClass.localeCompare(bClass);
                    }

                    // Sonra takım numarasına göre sırala
                    return parseInt(aTeam) - parseInt(bTeam);
                  });

                  return sortedStudentList.map((row) => {
                    if (!row.is_paid) return null;

                    const tb_2025_result =
                      row?.exam_results.find((r) => r.season === "2025_tb") ??
                      {};

                    const if_2025_result =
                      row?.exam_results.find((r) => r.season === "2025_if") ??
                      {};
                    if (
                      user.role === 0 &&
                      refLoginUsersTeamId.current !== tb_2025_result.team_id
                    )
                      return null;

                    const rowStyle =
                      user.role === 1
                        ? {
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            borderLeft: "3px solid #666",
                            backgroundColor: tb_2025_result.team_id
                              ? "#fafafa"
                              : "transparent",
                          }
                        : {};

                    // Takım bilgisini parçala ve anlamlı metin oluştur
                    const getTeamInfo = (teamId) => {
                      if (!teamId) return "Atanmadı";
                      const [sinif, okul, takim] = teamId.split("-");
                      return `${sinif}. Sınıflar - ${takim}. Takım`;
                    };

                    // Takım bilgisini sadece değiştiğinde göster
                    const showTeamInfo = tb_2025_result.team_id !== lastTeamId;

                    // Takım başlığı satırı
                    const teamHeaderRow =
                      showTeamInfo && tb_2025_result.team_id ? (
                        <TableRow>
                          <TableCell
                            colSpan={user.role === 1 ? 8 : 8}
                            style={{
                              backgroundColor: "#f0f0f0",
                              borderTop: "2px solid #666",
                              borderBottom: "2px solid #666",
                              padding: "12px 16px",
                              fontWeight: "bold",
                              fontSize: "1em",
                              textAlign: "center",
                              color: "#333",
                            }}
                          >
                            {getTeamInfo(tb_2025_result.team_id)}
                          </TableCell>
                        </TableRow>
                      ) : null;

                    lastTeamId = tb_2025_result.team_id;
                    const ifResultText = if_2025_result.conclusion
                      ? IF_RESULT_TEXTS[if_2025_result.conclusion].short
                      : "Sınava Katılmadı";
                    return (
                      <React.Fragment key={row.user_id + "user_id"}>
                        {teamHeaderRow}
                        <TableRow style={rowStyle}>
                          <TableCell align="center">{row.user_id}</TableCell>
                          <TableCell align="left">{row.name}</TableCell>
                          {user.role === 1 && (
                            <TableCell align="left">{row.email}</TableCell>
                          )}
                          {/* <TableCell
                            align="center"
                            style={{ margin: 0, padding: 0 }}
                          >
                            {row.class_id}
                          </TableCell> */}
                        
                          <TableCell align="center">
                            {if_2025_result.score}
                          </TableCell>

                          <TableCell align="center"> {ifResultText}</TableCell>
                          {/* {user.role === 1 && (
                            <TableCell align="center">
                              {tb_2025_result.team_id || "Atanmadı"}
                            </TableCell>
                          )} */}
                          {user.role === 1 && (
                            <TableCell
                              align="center"
                              style={{
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              {tb_2025_result.team_id &&
                                teamExamLink({
                                  usr: row,
                                  buttonName: "İ.F Sınavı",
                                  size: "small",
                                  season: "if_2425",
                                  teamId: tb_2025_result.team_id,
                                })}
                            </TableCell>
                          )}
                          {/* {user.role === 1 && (
                      <TableCell align="center">
                        {row.is_paid ? "ÖDENDİ" : "Ödenmedi"}
                      </TableCell>
                    )} */}
                          {/* <TableCell align="center">
                      {!row.is_paid && user.role === 1 && (
                        <Checkbox
                          // name={c}
                          // checked={classes[c]}
                          onChange={(e) => {
                            let tempSelectedStudents = { ...selectedStudents };
                            if (e.target.checked) {
                              tempSelectedStudents[row.user_id] = true;
                            } else {
                              delete tempSelectedStudents[row.user_id];
                            }

                            setSelectedStudents(tempSelectedStudents);
                          }}
                        />
                      )}
                    </TableCell> */}
                        </TableRow>
                      </React.Fragment>
                    );
                  });
                })()}
              </TableBody>
            </Table>
          )}
          {/* {Object.keys(selectedStudents).length > 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
              }}
            >
              <Button onClick={onPurchaseClickedForSelectedStudents}>
                {"Seçilen " +
                  Object.keys(selectedStudents).length +
                  " Yarışmacı İçin Ödeme Yap"}
              </Button>
            </div>
          )} */}
        </TableContainer>
      </Box>
      {user.role === 1 && (
        <div
          style={{
            width: "100%",

            marginTop: 10,
          }}
        >
          {/* <Typography
            style={{
              fontStyle: "italic",
              color: "orange",
              marginTop: 10,
              fontSize: "16px",
            }}
          >
            {
              "Öğretmenler, sorumlu oldukları sınıfı seçmekle yükümlüdür. Lütfen seçim yapmayı unutmayınız..."
            }
          </Typography>
          <Typography
            style={{
              fontStyle: "italic",
              color: "orange",
              marginTop: 10,
              fontSize: "16px",
            }}
          >
            {
              " Öğrencilerinizden sınıf seçimi yapmayanlar bu listede görünmez, lütfen öğrencileri uyarınız..."
            }
          </Typography> */}
          {/*  <Typography
            style={{
              fontStyle: "italic",
              color: "orange",
              marginTop: 10,
              fontSize: "16px",
            }}
          >
            {
              "Öğrencileriniz için kurum adına ödeme yapmak istiyorsanız,kurumun bilgisi dahilinde, kurumun kart bilgileri ile ödeme işlemini gerçekleştirmelisiniz."
            }
          </Typography> */}
        </div>
      )}
    </Box>
  );
  const teamId = user?.exam_results?.find(
    (er) => er.season === "2025_tb"
  )?.team_id;

  if (redirectPage) return <Redirect to={redirectPage} />;
  const objectionBox = (
    <GoogleFormsLink
      buttonName="Puanlamaya İtirazlar"
      formLink={
        "https://docs.google.com/forms/d/e/1FAIpQLSeuelVK3nEf3pBCqiYK9RmeE8zfsUS-3xpLOyV_CXVJJfch9w/viewform?usppp_url&entry.1157331533=" +
        user.id +
        "&entry.427270648=" +
        user.email +
        "&entry.1902692000=" +
        user.class_id
      }
      description="Bu formdan sadece puanlara dair itirazlar kabul edilmektedir.Son itiraz tarihi 28 Şubat 2025 Cuma saat 23.59 ' dur. Bu tarihten sonra puanlara yapılacak itirazların değerlendirmeye alınmayacağını belirtmek isteriz."
    />
  );
  return (
    <div className={"card " + classNames.left_step}>
      <Typography size="small" color="white" font="tadao" weight="normal">
        İL FİNALLERİ SINAVI
      </Typography>
      {/* {user.role === 1 && (
        <Typography
          style={{
            fontStyle: "italic",
            color: "orange",
            marginTop: 10,
            fontSize: "16px",
          }}
        >
          Öğrencilerinizin puanları ve takım bilgileri aşağıdaki tabloda
          bulunmaktadır. Ayrıca, bölge finallerine katılmaya hak kazanan
          takımlar ve ilgili detaylar da tabloda yer almaktadır.
        </Typography>
      )} */}

      {didStudentPay && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {/* <Typography
            style={{
              fontStyle: "italic",
              color: "orange",
              marginTop: 10,
              fontSize: "16px",
            }}
          >
            {
              "Kayıt işleminiz tamamlanmıştır.Takım Belirleme Sınavı, 15 Şubat 2025 Cumartesi günü saat 13.00'te başlayacak ve 14.15'te sona erecektir. Sınav süresi 75 dakikadır. Sınav saati geldiğinde, bu sayfada 'Sınava Katıl' butonu aktif hale gelecektir."
            }
          </Typography> */}
          {/* <Typography
            style={{
              fontStyle: "italic",
              color: "orange",
              marginTop: 10,
              fontSize: "16px",
            }}
          >
            {
              "Lütfen sınava başlamadan önce okul/sınıf bilgilerinizi kontrol ediniz. Hata varsa düzeltiniz. Bilgilerinizin hatalı olması durumunda diskalifiye edilebilirsiniz."
            }
          </Typography> */}
       
        </div>
      )}

      {user.role === 0 && (
        <Typography
          style={{
            fontStyle: "italic",
            color: "orange",
            marginTop: 10,
            fontSize: "16px",
          }}
        >
          İl Finalleri sınav sonuçlarınız, takım bilgileriniz ve bölge
          finallerine katılım durumunuz aşağıdaki tabloda açıklanmıştır. Bölge
          Finallerine ait süreci<strong> Takvim </strong> sayfasından takip
          edebilirsiniz.
        </Typography>
      )}
      {user.role === 1 && (
        <Typography
          style={{
            fontStyle: "italic",
            color: "orange",
            marginTop: 10,
            fontSize: "16px",
          }}
        >
          Öğrencilerinizin İl Finalleri sınav sonuçları, takım bilgileri ve
          bölge finallerine katılım durumu aşağıdaki tabloda açıklanmıştır.
          Bölge Finallerine ait süreci<strong> Takvim </strong> sayfasından
          takip edebilirsiniz.
        </Typography>
      )}
      {teamId &&
        teamExamLink({
          usr: user,
          season: "if_2425",
          buttonName: "İl Finalleri Sınavı",
          teamId: teamId,
        })}
      {detailedUpdatesLink("İl Finalleri Sonuçları")}
      {studentTableTB}
    </div>
  );
};
