import React from "react";
import classNames from "./style.module.css";
import Typography from "../../../../components/ui/typography";

const LastYearResults = () => (
  <div className={classNames.sponsors}>
    <Typography size="big" color="orange" font="merel">
      2019 Türkiye Okullar Arası Zeka Oyunları Yarışması Sonuçları
    </Typography>
    <Typography
      size="small"
      color="white"
      font="tadao"
      weight="normal"
      className={classNames.description}
    >
      Pandemi nedeniyle ertelenen ödül töreni, 26 Ekim Pazartesi 19.00'da Millî
      Eğitim Bakanı Prof. Dr. Ziya Selçuk ve Türkiye Zeka Vakfı Başkanı Emrehan
      Halıcı'nın tebrik mesajları ile beraber aşağıdaki videodan
      yayınlanacaktır. Dereceye giren okulların kupa, madalya ve diğer belgeleri
      sorumlu öğretmenlerle iletişime geçilerek kargolanacaktır. Tüm okulları,
      takım üyelerini ve sorumlu öğretmenleri tebrik ederiz.
    </Typography>
    <div className={"card " + classNames.sponsor_card}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/NWl8TOdAP30"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
);

export default LastYearResults;
