import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import { getDistrictSchools as getDistrictSchoolsAction } from "../../../store/actions/generic";
import Input from "../../../components/ui/input/input";
import Button from "@material-ui/core/Button";
import {
  GET_USER_BY_ID,
  UPDATE_USER_BY_ID,
  DELETE_USER,
  actionTypeSuccess,
  SIGN_IN,
} from "../../../store/actionTypes";
import { schoolCities } from "../../../common/constants";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  updateUserById,
  deleteUser,
  createAPaidReceipt,
  cancelAPaidReceipt,
} from "../../../store/actions/generic";
import Modal from "@material-ui/core/Modal";
import MaterialBackDrop from "../../../components/ui/loading/materialBackDrop";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "../../../components/ui/typography";
import { actionCreate } from "../../../store/actions/actionCreator";
import { setAuthHeadersToLocalStorage } from "../../../helpers/localStorageHelper";
import { Grid } from "@material-ui/core";
import { EXAM_SEASONS } from "../../../common/examData";
import sha256 from "crypto-js/sha256";
import Hex from "crypto-js/enc-hex";
import { teamExamLink } from "../components/generateLinks";

// import { competitorList, verdict, verdictNames } from "../../../common/ilFinaliData_11";

const UpdateUser = ({ onClose, selectedUser }) => {
  const authenticatedUser = useSelector(
    (state) => state.auth && state.auth.user
  );
  //const selectedUser = user; //useSelector((state) => state.generic.selectedUser);

  const reloadDistrictSchools = (city, district) => {
    dispatch(getDistrictSchoolsAction(city, district));
  };

  const loadingUserUpdate = useSelector(
    (state) => state.loading[UPDATE_USER_BY_ID]
  );

  const loadingSelectedUser = useSelector(
    (state) => state.loading[GET_USER_BY_ID]
  );

  const loadingDeleteUser = useSelector((state) => state.loading[DELETE_USER]);

  const dispatch = useDispatch();
  const districtSchools = useSelector((state) => state.generic.districtSchools);

  const [params, setParams] = useState({
    selectedUserId: selectedUser.id,
    selectedUserName: selectedUser.name,
    selectedUserEmail: selectedUser.email,
    selectedUserPhone: selectedUser.phone,
    selectedUserDistrict: selectedUser.district,
    selectedUserCity: selectedUser.city,
    selectedUserSchoolId: selectedUser.school_id,
    selectedUserRole: selectedUser.role,
    selectedUserClass: selectedUser.class_id,
    classCoaches: {},
    newPass: "",
  });

  const setParam = (key, value) => {
    let newVal = value;
    if (key === "selectedUserEmail") {
      newVal = value.toLocaleLowerCase("tr-TR");
    }

    setParams({
      ...params,
      [key]: newVal,
    });
  };

  // const setParam = ({ target: { name, value } }) => {
  //   let newVal = value;
  //   if(name ==="name" || name ==="surname") newVal= value.toLocaleUpperCase();
  //   else if(name ==="email" || name ==="email_2") newVal= value.toLocaleLowerCase();
  //   setParam(name, newVal)};

  useEffect(() => {
    reloadDistrictSchools(selectedUser.city, selectedUser.district);
  }, []);

  useEffect(() => {
    let tempCoachClasses = { ...params.classCoaches };
    let coachesFromDb = selectedUser.all_coaches_of_this_school;
    for (const coach of coachesFromDb) {
      const { id, class_id, name } = coach;

      tempCoachClasses[class_id] = {
        coachName: name,
        coachId: id,
      };
    }

    setParam("classCoaches", tempCoachClasses);
  }, [selectedUser.all_coaches_of_this_school]);

  if (!selectedUser) return null;
  if (loadingSelectedUser || loadingUserUpdate) return <MaterialBackDrop />;

  const deleteUserButton = authenticatedUser.role === 2 &&
    selectedUser.paid_receipts.length === 0 && ( // ücret ödeyenleri sistemden kolayca silebilmek hoş olmaz.
      <div
        style={{
          display: "flex",
          fontSize: "1.5rem",
          justifyContent: "space-between",
        }}
      >
        {" "}
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            if (
              window.confirm(
                selectedUser.name +
                  "  kişisine ait tüm verileri sistemden silmek istediğinize emin misiniz?"
              ) &&
              window.confirm("Derin bi nefes al bi daha düşün... Emin misin?")
            ) {
              dispatch(deleteUser(selectedUser.id)).then((res) => {
                if (res) {
                  alert(res.message);
                  onClose();
                } else alert("HATA: Kişi Silinemedi.");
              });
            }
          }}
        >
          SİSTEMDEN SİL
        </Button>
      </div>
    );

  const emailInput = (
    <div style={{ width: "100%", maxWidth: 400 }}>
      <Input
        style={{ fontSize: 16 }}
        height={40}
        labelColor="black"
        label="Email"
        name="selectedUserEmail"
        onChange={({ target: { name, value } }) => setParam(name, value)}
        value={params.selectedUserEmail}
      />
    </div>
  );

  const classChangerStudent = selectedUser.role === 0 && (
    <div style={{ width: 150 }}>
      <Typography color={"black"} font="tadao" size="smaller" weight="normal">
        Sınıf{" "}
      </Typography>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={params.selectedUserClass}
        onChange={(e) => {
          setParam("selectedUserClass", e.target.value);
        }}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((c) => (
          <MenuItem value={c}>
            {c === 9 ? "Hazırlık / 9. Sınıf" : c + ". Sınıf"}{" "}
          </MenuItem>
        ))}
      </Select>
    </div>
  );

  const classChangerCoach = selectedUser.role === 1 && (
    <div>
      <Typography color={"black"} font="tadao" size="smaller" weight="normal">
        Sınıf{" "}
      </Typography>
      <div>
        <Grid container rowSpacing={3}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((s) => {
            let nameOfTheCoach = "";
            let idOfTheCoach = "";

            const coachData = params.classCoaches[s];
            if (coachData) {
              nameOfTheCoach = " (" + coachData.coachName + ")";
              idOfTheCoach = coachData.coachId;
            }

            const isThisBoxDisabled =
              authenticatedUser.role < 2 &&
              idOfTheCoach &&
              idOfTheCoach !== selectedUser.id;

            return (
              <Grid xs={3} item key={"class_checker_" + s}>
                <Checkbox
                  checked={coachData}
                  value={params.selectedUserClass}
                  disabled={isThisBoxDisabled}
                  onChange={() => {
                    let tempCoachClasses = { ...params.classCoaches };
                    if (coachData) delete tempCoachClasses[s];
                    // datayı ekle
                    else {
                      tempCoachClasses[s] = {
                        coachName: selectedUser.name,
                        coachId: selectedUser.id,
                      };
                    }
                    setParam("classCoaches", tempCoachClasses);
                  }}
                />
                {s === 9
                  ? "Hazırlık / 9. Sınıf" + nameOfTheCoach
                  : s + ". Sınıf" + nameOfTheCoach}
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );

  const braiquireCouponCode = selectedUser.brain_quire_coupon_code &&
    authenticatedUser.role === 2 && (
      <div style={{ marginTop: 15 }}>
        {"Griceviz kupon kodu:  " +
          selectedUser.brain_quire_coupon_code.coupon_code}
      </div>
    );

  const roleChanger = authenticatedUser.role === 2 && (
    <div
      style={{
        // marginTop: "30px",
        // marginBottom: "30px",
        fontSize: "1.1rem",
      }}
    >
      <Checkbox
        checked={params.selectedUserRole == 0}
        onChange={(e) => {
          setParam("selectedUserRole", e.target.checked ? 0 : 1);
        }}
        name="selectedUserRoleCheck"
        style={{
          color: "black",
          padding: 0,
          marginRight: "10px",
        }}
      />
      {"Öğrenci"}
      <Checkbox
        checked={params.selectedUserRole == 1}
        onChange={(e) => {
          setParam("selectedUserRole", e.target.checked ? 1 : 0);
        }}
        name="selectedUserRoleCheck"
        style={{
          color: "black",
          padding: 0,
          marginLeft: "50px",
          marginRight: "10px",
        }}
      />
      {"Öğretmen"}
    </div>
  );

  const paymentStatusChanger = authenticatedUser.role === 2 &&
    selectedUser.role === 0 &&
    selectedUser.paid_receipts.length === 0 && (
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            if (
              window.confirm(
                "Bu kişinin ücret durumu 'ödendi' olarak değişsin mi?"
              ) &&
              window.confirm("İyice düşünüp taşındın mı?")
            ) {
              const product_id = 20; // TODO: Bu çok amele oldu, başka bir çözüm düşünmek gerek.
              dispatch(createAPaidReceipt(selectedUser.id, product_id)).then(
                (res) => {
                  if (res) {
                    alert(res.message);
                  } else
                    alert("HATA: Bişeyler ters gitti... Keşke düz gitseydi...");
                }
              );
            }
          }}
        >
          'Ödendi' Yap
        </Button>
      </div>
    );

  const paymentCanceler = authenticatedUser.role === 2 &&
    selectedUser.role === 0 &&
    selectedUser.paid_receipts.length === 1 && (
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            if (window.confirm("Bu kişinin ödemesi iptal edilsin mi?")) {
              const product_id = 20; // TODO: Bu çok amele oldu, başka bir çözüm düşünmek gerek.
              dispatch(cancelAPaidReceipt(selectedUser.id, product_id)).then(
                (res) => {
                  if (res) {
                    alert(res.message);
                  } else
                    alert("HATA: Bişeyler ters gitti... Keşke düz gitseydi...");
                }
              );
            }
          }}
        >
          Ödemeyi İptal Et
        </Button>
      </div>
    );

  const passwordRenewer = authenticatedUser.role === 2 && (
    <div style={{ width: "180px" }}>
      <Input
        labelColor="black"
        label="Yeni Şifre"
        name="newPass"
        onChange={({ target: { name, value } }) => setParam(name, value)}
        value={params.newPass}
      />
    </div>
  );

  const nameSurnameInput = (
    <div style={{ width: "100%", maxWidth: 400 }}>
      <Input
        style={{ fontSize: 16 }}
        labelColor="black"
        label="Adı-Soyadı"
        name="selectedUserName"
        onChange={({ target: { name, value } }) => setParam(name, value)}
        value={params.selectedUserName}
      />
    </div>
  );

  const phoneInput = (
    <div style={{ width: "100%", maxWidth: 400 }}>
      <Input
        style={{ fontSize: 16 }}
        labelColor="black"
        label="Telefon"
        name="selectedUserPhone"
        onChange={({ target: { name, value } }) => setParam(name, value)}
        value={params.selectedUserPhone}
      />
    </div>
  );

  const schoolChooser = (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ marginRight: "15px", width: "300px" }}>
        <Input
          defaultValue={{
            value: params.selectedUserCity,
            title: params.selectedUserCity,
          }}
          labelColor="black"
          name="selectedUserCity"
          label="Okul İli"
          select
          value={params.selectedUserCity}
          onChange={(e, newItem) => {
            setParams({
              ...params,
              selectedUserDistrict: "",
              selectedUserCity: newItem ? newItem.value : "",
              selectedUserSchoolId: "",
            });
          }}
          options={Object.keys(schoolCities)
            .sort((a, b) => a.localeCompare(b))
            .map((cityName) => {
              return { value: cityName, title: cityName };
            })}
        />
      </div>
      <div style={{ marginRight: "15px", width: "300px" }}>
        <Input
          defaultValue={{
            value: params.selectedUserDistrict,
            title: params.selectedUserDistrict,
          }}
          labelColor="black"
          name="selectedUserDistrict"
          label="Okul İlçesi"
          select
          value={params.selectedUserDistrict}
          onChange={(e, newItem) => {
            setParams({
              ...params,
              selectedUserDistrict: newItem ? newItem.value : "",
              selectedUserSchoolId: "",
            });
            const district = newItem ? newItem.value : "";
            reloadDistrictSchools(params.selectedUserCity, district);
          }}
          options={
            schoolCities[params.selectedUserCity] &&
            schoolCities[params.selectedUserCity]
              .sort((a, b) => {
                return a.localeCompare(b);
              })
              .map((districtName) => {
                return { value: districtName, title: districtName };
              })
          }
        />
      </div>
      <div style={{ flex: 1, width: "100%", maxWidth: 400 }}>
        <Input
          defaultValue={{
            value: params.selectedUserSchoolId,
            title: selectedUser.school_name,
          }}
          labelColor="black"
          name="selectedUserSchoolId"
          label="Okul"
          select
          value={params.selectedUserSchoolId}
          onChange={(e, newItem) => {
            setParams({
              ...params,
              selectedUserSchoolId: newItem ? newItem.value : "",
              classCoaches: {},
            });
          }}
          options={districtSchools.map((s) => ({ value: s.id, title: s.name }))}
        />
      </div>
    </div>
  );

  const generatePersonalExamLink = ({ usr, buttonName, season, size }) => {
    // TODO: merge this with manage.js
    const { class_id, id, name, role } = usr;
    if (role !== 0 || !class_id) return null;

    let [examId, examQuestionsId] = EXAM_SEASONS[season].exam_ids[class_id];

    const id_hash = sha256(id.toString() + "tuz").toString(Hex);
    const user_identifier = id + id_hash;

    const regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#'"]/gi;
    let userNameForUrl = name.trim().replaceAll(regex_symbols, ""); // Özel karakterleri silelim, url'de hata çıkmasına sebep olur.
    userNameForUrl = userNameForUrl.replaceAll(" ", "+");

    const link =
      "https://sinav.tzv.org.tr/linkedExam/" +
      examId +
      "&" +
      examQuestionsId +
      "&" +
      user_identifier +
      "&" +
      userNameForUrl;

    return (
      <a href={link} target="_blank">
        <Button style={{ backgroundColor: "green" }}>
          {buttonName ?? "İl Finalleri Sınavı"}
        </Button>
      </a>
    );
  };

  const teamId = selectedUser?.exam_results?.find(
    (er) => er.season === "2025_tb"
  )?.team_id;
  const ifButton =
    teamId &&
    teamExamLink({
      usr: selectedUser,
      season: "if_2425",
      buttonName: "İl Finalleri Sınavı",
      teamId: teamId,
    });

  const saveCancelButtons = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "60px",
      }}
    >
      <Button variant="contained" color="primary" onClick={onClose}>
        ÇIK
      </Button>

      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          const email = params.selectedUserEmail.trim();
          const name = params.selectedUserName.trim();
          const phone = params.selectedUserPhone.trim();

          if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email))
            alert("Lütfen geçerli bir e-posta adresi yazınız.");
          else if (name.length < 3)
            alert("Lütfen ad-soyad bilgisini eksiksiz giriniz.");
          else if (phone.length < 10)
            alert("Lütfen en az 10 haneli telefon numaranızı yazınız.");
          else {
            // let class_coaches = [];
            let classCoaches = params.classCoaches;
            for (const key of Object.keys(classCoaches)) {
              if (classCoaches[key].user_id === selectedUser.id) {
                classCoaches.push(key);
              }
            }

            // let class_coaches= [];
            // for (const class_key of Object.keys(classCoaches)) {
            //   if(classCoaches[class_key].coachId === selectedUser.id){
            //     class_coaches.push(class_key)
            //   }
            // }

            dispatch(
              updateUserById({
                user_id: params.selectedUserId,
                school_id: params.selectedUserSchoolId,
                name,
                email,
                phone,
                role: params.selectedUserRole,
                class_id: params.selectedUserClass,
                new_pass: params.newPass.trim(),
                updater_role: authenticatedUser.role, // bazen normal kişilere update etmeyi kapatcaz, ama admine açık kalacak. Bu durumlarda lazım.
                class_coaches: classCoaches,
              })
            ).then((res) => {
              if (res) {
                if (res.deny_message) alert(res.deny_message);
                else {
                  let updatedUser = res;

                  if (authenticatedUser.id === selectedUser.id) {
                    setAuthHeadersToLocalStorage({
                      user: JSON.stringify(authenticatedUser),
                    });
                    dispatch(
                      actionCreate(actionTypeSuccess(SIGN_IN), {
                        user: updatedUser,
                      })
                    );
                  }

                  alert("Bilgileriniz başarıyla güncellendi.");
                }

                onClose({ refetchFilteredUsers: true });
              } else alert("HATA: Kayıt başarısız.");
            });
          }
        }}
      >
        KAYDET
      </Button>
    </div>
  );

  return (
    <Modal
      style={{ padding: "20px", overflow: "auto" }}
      open={true}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          padding: "calc(3px + 1vw)",
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        {authenticatedUser.role === 2 && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {roleChanger}
            {passwordRenewer}
            {deleteUserButton}
            {paymentStatusChanger}
            {paymentCanceler}
          </div>
        )}

        {selectedUser.role === 0 && authenticatedUser.role === 2 && ifButton}
        {emailInput}
        {nameSurnameInput}
        {phoneInput}
        {schoolChooser}
        {selectedUser.role === 0 && classChangerStudent}
        {selectedUser.role === 1 && classChangerCoach}
        {/* {selectedUser.brain_quire_coupon_code && braiquireCouponCode} */}
        {saveCancelButtons}
      </div>
    </Modal>
  );
};

export default UpdateUser;
