import { if_basari_base64 } from "../components/pdfcreate/1_il_finalleri/ogrenci_basari";
import { if_katilim_base64 } from "../components/pdfcreate/1_il_finalleri/katilim";
import { if_ogretmen_katilim_base64 } from "../components/pdfcreate/1_il_finalleri/ogretmen_katilim";
import { if_ogretmen_basari_base64 } from "../components/pdfcreate/1_il_finalleri/ogretmen_basari";
import { if_okul_katilim_base64 } from "../components/pdfcreate/1_il_finalleri/okul_katilim";
import { if_okul_basari_base64 } from "../components/pdfcreate/1_il_finalleri/okul_basari";
import { if_sebf_base64 } from "../components/pdfcreate/1_il_finalleri/sebf";
import { bf_basari_ogrenci_base64 } from "../components/pdfcreate/2_bolge_finalleri/bf_ogrenci_basari";
import { bf_katilim_ogrenci_base64 } from "../components/pdfcreate/2_bolge_finalleri/bf_ogrenci_katilim";
import { bf_basari_ogretmen_base64 } from "../components/pdfcreate/2_bolge_finalleri/bf_ogretmen_basari";
import { bf_katilim_ogretmen_base64 } from "../components/pdfcreate/2_bolge_finalleri/bf_ogretmen_katilim";
import { bf_basari_okul_base64 } from "../components/pdfcreate/2_bolge_finalleri/bf_okul_basari";
import { bf_katilim_okul_base64 } from "../components/pdfcreate/2_bolge_finalleri/bf_okul_katilim";
import { bf_sebf_base64 } from "../components/pdfcreate/2_bolge_finalleri/bf_sebf";

import { tf_basari_ogrenci_base64 } from "../components/pdfcreate/3_tr_finalleri/tf_ogrenci_basari";
import { tf_katilim_ogrenci_base64 } from "../components/pdfcreate/3_tr_finalleri/tf_ogrenci_katilim";
import { tf_basari_ogretmen_base64 } from "../components/pdfcreate/3_tr_finalleri/tf_ogretmen_basari";
import { tf_katilim_ogretmen_base64 } from "../components/pdfcreate/3_tr_finalleri/tf_ogretmen_katilim";
import { tf_basari_okul_base64 } from "../components/pdfcreate/3_tr_finalleri/tf_okul_basari";
import { tf_katilim_okul_base64 } from "../components/pdfcreate/3_tr_finalleri/tf_okul_katilim";
import { tf_sebf_base64 } from "../components/pdfcreate/3_tr_finalleri/tf_sebf";
import { tf_sebf_basari_base64 } from "../components/pdfcreate/3_tr_finalleri/tf_sebf_basari_v2";

import { base64_tf_table_no_info } from "../components/pdfcreate/3_tr_finalleri/base64_table_no_info";
import { base64_tf_izin_yazisi } from "../components/pdfcreate/3_tr_finalleri/base64_tf_izin_yazisi";

export const schoolCities = {
  KIRKLARELİ: [
    "LÜLEBURGAZ",
    "PINARHİSAR",
    "KOFÇAZ",
    "DEMİRKÖY",
    "BABAESKİ",
    "PEHLİVANKÖY",
    "VİZE",
    "MERKEZ",
  ],
  İSTANBUL: [
    "BEYKOZ",
    "SANCAKTEPE",
    "ŞİŞLİ",
    "ÜSKÜDAR",
    "AVCILAR",
    "ADALAR",
    "TUZLA",
    "KÜÇÜKÇEKMECE",
    "KADIKÖY",
    "KAĞITHANE",
    "ÜMRANİYE",
    "BÜYÜKÇEKMECE",
    "SARIYER",
    "FATİH",
    "ÇATALCA",
    "ATAŞEHİR",
    "BAKIRKÖY",
    "BEYLİKDÜZÜ",
    "SULTANGAZİ",
    "KARTAL",
    "GÜNGÖREN",
    "ÇEKMEKÖY",
    "BAHÇELİEVLER",
    "GAZİOSMANPAŞA",
    "BAYRAMPAŞA",
    "BAŞAKŞEHİR",
    "MALTEPE",
    "ZEYTİNBURNU",
    "ŞİLE",
    "SULTANBEYLİ",
    "BAĞCILAR",
    "PENDİK",
    "BEŞİKTAŞ",
    "EYÜPSULTAN",
    "ARNAVUTKÖY",
    "BÜYÜKŞEHİR",
    "ESENLER",
    "BEYOĞLU",
    "SİLİVRİ",
    "ESENYURT",
  ],
  VAN: [
    "EDREMİT",
    "İPEKYOLU",
    "GEVAŞ",
    "ERCİŞ",
    "TUŞBA",
    "MURADİYE",
    "ÇATAK",
    "SARAY",
    "BÜYÜKŞEHİR",
    "GÜRPINAR",
    "ÇALDIRAN",
    "BAŞKALE",
    "BAHÇESARAY",
    "ÖZALP",
  ],
  ERZİNCAN: [
    "ÜZÜMLÜ",
    "İLİÇ",
    "TERCAN",
    "REFAHİYE",
    "ÇAYIRLI",
    "MERKEZ",
    "KEMALİYE",
    "OTLUKBELİ",
    "KEMAH",
  ],
  "YURT DIŞI": [
    "Uganda Cumhuriyeti",
    "Tunus",
    "Arjantin",
    "Kosova",
    "Lübnan",
    "Bosna Hersek",
    "Bulgaristan",
    "Rusya",
    "Kazakistan",
    "Hollanda",
    "Yunanistan",
    "Polonya",
    "Mısır",
    "Makedonya",
    "Irak",
    "Türkmenistan",
    "Belarus",
    "Cibuti Cumhuriyeti",
    "Çin",
    "Somali Federal Cumhuriyeti ",
    "Nijer",
    "Bolivarcı Venezuela Cumhuriyeti",
    "Slovakya",
    "Kırgızistan",
    "Karadağ",
    "Tacikistan",
    "Katar",
    "Senegal Cumhuriyeti",
    "İran",
    "Pakistan",
    "İsveç",
    "Etiyopya Federal Demokratik Cumhuriyeti",
    "Azerbaycan",
    "İngiltere",
    "Güney Kore",
    "ABD",
    "Endonezya",
    "Slovenya",
    "Arnavutluk",
    "Güney Afrika Cumhuriyeti",
    "Romanya",
    "S.Arabistan",
    "Danimarka",
    "Çek Cumhuriyeti",
    "Özbekistan",
    "Filistin",
    "İsrail",
    "İtalya",
    "İsviçre",
    "İspanya Krallığı",
    "Fransa",
    "Portekiz",
    "Finlandiya",
    "Belçika",
    "Gine Cumhuriyeti",
    "Avusturalya",
    "Kuveyt",
    "Malezya",
    "Sudan",
    "Libya",
    "Sırbistan",
    "Moritanya İslam Cumhuriyeti",
    "Bahreyn",
    "Ürdün",
    "Ukrayna",
    "Nijerya Federal Cumhuriyeti",
    "Afganistan",
    "Moldova",
    "Gürcistan",
    "Litvanya",
    "Avusturya",
    "Kanada",
    "Almanya",
    "İrlanda",
    "Hırvatistan",
    "Macaristan",
    "Mali Cumhuriyeti",
    "Kuzey Kıbrıs Türk Cumhuriyeti",
    "Birleşik Arap Emirlikleri",
  ],
  KİLİS: ["MERKEZ", "ELBEYLİ", "POLATELİ", "MUSABEYLİ"],
  DİYARBAKIR: [
    "ÇINAR",
    "KAYAPINAR",
    "BİSMİL",
    "ERGANİ",
    "YENİŞEHİR",
    "SUR",
    "BAĞLAR",
    "BÜYÜKŞEHİR",
    "ÇERMİK",
    "KOCAKÖY",
    "EĞİL",
    "SİLVAN",
    "HAZRO",
    "DİCLE",
    "KULP",
    "ÇÜNGÜŞ",
    "HANİ",
    "LİCE",
  ],
  TRABZON: [
    "ÇAYKARA",
    "OF",
    "ŞALPAZARI",
    "SÜRMENE",
    "AKÇAABAT",
    "MAÇKA",
    "VAKFIKEBİR",
    "ORTAHİSAR",
    "BEŞİKDÜZÜ",
    "KÖPRÜBAŞI",
    "ARSİN",
    "DÜZKÖY",
    "BÜYÜKŞEHİR",
    "ARAKLI",
    "DERNEKPAZARI",
    "HAYRAT",
    "YOMRA",
    "ÇARŞIBAŞI",
    "TONYA",
  ],
  MALATYA: [
    "YAZIHAN",
    "DARENDE",
    "ARGUVAN",
    "KULUNCAK",
    "MERKEZ",
    "PÜTÜRGE",
    "BATTALGAZİ",
    "AKÇADAĞ",
    "YEŞİLYURT",
    "HEKİMHAN",
    "DOĞANYOL",
    "ARAPGİR",
    "DOĞANŞEHİR",
    "BÜYÜKŞEHİR",
    "KALE",
  ],
  ELAZIĞ: [
    "KARAKOÇAN",
    "BASKİL",
    "KOVANCILAR",
    "AĞIN",
    "PALU",
    "ARICAK",
    "MADEN",
    "SİVRİCE",
    "ALACAKAYA",
    "MERKEZ",
    "KEBAN",
  ],
  DENİZLİ: [
    "BOZKURT",
    "BÜYÜKŞEHİR",
    "BEKİLLİ",
    "TAVAS",
    "KALE",
    "PAMUKKALE",
    "SERİNHİSAR",
    "GÜNEY",
    "SARAYKÖY",
    "ACIPAYAM",
    "BABADAĞ",
    "HONAZ",
    "BEYAĞAÇ",
    "BULDAN",
    "BAKLAN",
    "ÇİVRİL",
    "ÇAL",
    "ÇARDAK",
    "MERKEZEFENDİ",
    "ÇAMELİ",
  ],
  RİZE: [
    "ÇAMLIHEMŞİN",
    "DEREPAZARI",
    "ARDEŞEN",
    "GÜNEYSU",
    "İYİDERE",
    "FINDIKLI",
    "PAZAR",
    "MERKEZ",
    "KALKANDERE",
    "ÇAYELİ",
    "İKİZDERE",
    "HEMŞİN",
  ],
  AFYONKARAHİSAR: [
    "İHSANİYE",
    "DİNAR",
    "SİNANPAŞA",
    "BOLVADİN",
    "EVCİLER",
    "ÇOBANLAR",
    "DAZKIRI",
    "SANDIKLI",
    "BAŞMAKÇI",
    "İSCEHİSAR",
    "EMİRDAĞ",
    "ÇAY",
    "SULTANDAĞI",
    "MERKEZ",
    "BAYAT",
    "KIZILÖREN",
    "ŞUHUT",
    "HOCALAR",
  ],
  ÇORUM: [
    "İSKİLİP",
    "KARGI",
    "BOĞAZKALE",
    "OĞUZLAR",
    "MERKEZ",
    "ALACA",
    "UĞURLUDAĞ",
    "ORTAKÖY",
    "LAÇİN",
    "MECİTÖZÜ",
    "DODURGA",
    "BAYAT",
    "OSMANCIK",
    "SUNGURLU",
  ],
  SAKARYA: [
    "ERENLER",
    "SERDİVAN",
    "ADAPAZARI",
    "SÖĞÜTLÜ",
    "PAMUKOVA",
    "FERİZLİ",
    "TARAKLI",
    "AKYAZI",
    "BÜYÜKŞEHİR",
    "HENDEK",
    "KOCAALİ",
    "KAYNARCA",
    "ARİFİYE",
    "SAPANCA",
    "KARASU",
    "GEYVE",
    "KARAPÜRÇEK",
  ],
  SİNOP: [
    "ERFELEK",
    "MERKEZ",
    "DURAĞAN",
    "GERZE",
    "SARAYDÜZÜ",
    "BOYABAT",
    "DİKMEN",
    "AYANCIK",
    "TÜRKELİ",
  ],
  BURDUR: [
    "KARAMANLI",
    "MERKEZ",
    "ALTINYAYLA",
    "KEMER",
    "ÇAVDIR",
    "BUCAK",
    "YEŞİLOVA",
    "GÖLHİSAR",
    "TEFENNİ",
    "AĞLASUN",
    "ÇELTİKÇİ",
  ],
  SİVAS: [
    "AKINCILAR",
    "ŞARKIŞLA",
    "KOYULHİSAR",
    "YILDIZELİ",
    "SUŞEHRİ",
    "GEMEREK",
    "ALTINYAYLA",
    "ULAŞ",
    "ZARA",
    "MERKEZ",
    "DOĞANŞAR",
    "DİVRİĞİ",
    "KANGAL",
    "HAFİK",
    "İMRANLI",
    "GÖLOVA",
    "GÜRÜN",
  ],
  HATAY: [
    "REYHANLI",
    "İSKENDERUN",
    "SAMANDAĞ",
    "DÖRTYOL",
    "BÜYÜKŞEHİR",
    "KIRIKHAN",
    "ERZİN",
    "PAYAS",
    "DEFNE",
    "KUMLU",
    "BELEN",
    "ANTAKYA",
    "ALTINÖZÜ",
    "ARSUZ",
    "YAYLADAĞI",
    "HASSA",
  ],
  BATMAN: ["MERKEZ", "BEŞİRİ", "SASON", "KOZLUK", "HASANKEYF", "GERCÜŞ"],
  ORDU: [
    "KABADÜZ",
    "KUMRU",
    "AKKUŞ",
    "MESUDİYE",
    "KABATAŞ",
    "ALTINORDU",
    "BÜYÜKŞEHİR",
    "ULUBEY",
    "ÇAYBAŞI",
    "ÇAMAŞ",
    "FATSA",
    "GÜLYALI",
    "ÜNYE",
    "AYBASTI",
    "KORGAN",
    "İKİZCE",
    "ÇATALPINAR",
    "GÖLKÖY",
    "GÜRGENTEPE",
    "PERŞEMBE",
  ],
  ŞIRNAK: [
    "SİLOPİ",
    "CİZRE",
    "MERKEZ",
    "GÜÇLÜKONAK",
    "İDİL",
    "ULUDERE",
    "BEYTÜŞŞEBAP",
  ],
  ESKİŞEHİR: [
    "GÜNYÜZÜ",
    "ODUNPAZARI",
    "SEYİTGAZİ",
    "HAN",
    "SİVRİHİSAR",
    "BÜYÜKŞEHİR",
    "MİHALGAZİ",
    "ALPU",
    "SARICAKAYA",
    "İNÖNÜ",
    "BEYLİKOVA",
    "TEPEBAŞI",
    "MAHMUDİYE",
    "MİHALIÇÇIK",
    "ÇİFTELER",
  ],
  KONYA: [
    "BEYŞEHİR",
    "YUNAK",
    "SARAYÖNÜ",
    "AKÖREN",
    "GÜNEYSINIR",
    "ÇELTİK",
    "TUZLUKÇU",
    "SELÇUKLU",
    "BOZKIR",
    "KARAPINAR",
    "HÜYÜK",
    "EMİRGAZİ",
    "YALIHÜYÜK",
    "MERAM",
    "ILGIN",
    "DERBENT",
    "KADINHANI",
    "TAŞKENT",
    "HADİM",
    "EREĞLİ",
    "DEREBUCAK",
    "HALKAPINAR",
    "ALTINEKİN",
    "KARATAY",
    "DOĞANHİSAR",
    "SEYDİŞEHİR",
    "KULU",
    "BÜYÜKŞEHİR",
    "AHIRLI",
    "ÇUMRA",
    "AKŞEHİR",
    "CİHANBEYLİ",
  ],
  ADIYAMAN: [
    "SAMSAT",
    "BESNİ",
    "SİNCİK",
    "MERKEZ",
    "GÖLBAŞI",
    "TUT",
    "ÇELİKHAN",
    "GERGER",
    "KAHTA",
  ],
  UŞAK: ["KARAHALLI", "SİVASLI", "MERKEZ", "EŞME", "BANAZ", "ULUBEY"],
  GÜMÜŞHANE: ["KÜRTÜN", "KELKİT", "ŞİRAN", "KÖSE", "MERKEZ", "TORUL"],
  YALOVA: ["ALTINOVA", "MERKEZ", "ARMUTLU", "TERMAL", "ÇİFTLİKKÖY", "ÇINARCIK"],
  BİLECİK: [
    "BOZÜYÜK",
    "OSMANELİ",
    "YENİPAZAR",
    "PAZARYERİ",
    "İNHİSAR",
    "MERKEZ",
    "SÖĞÜT",
    "GÖLPAZARI",
  ],
  ERZURUM: [
    "BÜYÜKŞEHİR",
    "PASİNLER",
    "KÖPRÜKÖY",
    "HINIS",
    "TEKMAN",
    "YAKUTİYE",
    "TORTUM",
    "HORASAN",
    "KARAÇOBAN",
    "AŞKALE",
    "AZİZİYE",
    "UZUNDERE",
    "PALANDÖKEN",
    "KARAYAZI",
    "PAZARYOLU",
    "ÇAT",
    "OLUR",
    "ŞENKAYA",
    "OLTU",
    "İSPİR",
    "NARMAN",
  ],
  BALIKESİR: [
    "SAVAŞTEPE",
    "KEPSUT",
    "SINDIRGI",
    "BURHANİYE",
    "GÖNEN",
    "ALTIEYLÜL",
    "AYVALIK",
    "HAVRAN",
    "SUSURLUK",
    "KARESİ",
    "GÖMEÇ",
    "ERDEK",
    "İVRİNDİ",
    "MARMARA",
    "EDREMİT",
    "DURSUNBEY",
    "BÜYÜKŞEHİR",
    "MANYAS",
    "BİGADİÇ",
    "BANDIRMA",
    "BALYA",
  ],
  GİRESUN: [
    "DOĞANKENT",
    "TİREBOLU",
    "KEŞAP",
    "ÇANAKÇI",
    "DERELİ",
    "ESPİYE",
    "ŞEBİNKARAHİSAR",
    "ALUCRA",
    "MERKEZ",
    "PİRAZİZ",
    "EYNESİL",
    "ÇAMOLUK",
    "YAĞLIDERE",
    "BULANCAK",
    "GÜCE",
    "GÖRELE",
  ],
  ARTVİN: [
    "KEMALPAŞA",
    "HOPA",
    "ARDANUÇ",
    "YUSUFELİ",
    "MURGUL",
    "MERKEZ",
    "ŞAVŞAT",
    "BORÇKA",
    "ARHAVİ",
  ],
  BOLU: [
    "GEREDE",
    "SEBEN",
    "DÖRTDİVAN",
    "MENGEN",
    "MERKEZ",
    "MUDURNU",
    "YENİÇAĞA",
    "GÖYNÜK",
    "KIBRISCIK",
  ],
  KARS: [
    "AKYAKA",
    "DİGOR",
    "ARPAÇAY",
    "MERKEZ",
    "SARIKAMIŞ",
    "SUSUZ",
    "SELİM",
    "KAĞIZMAN",
  ],
  ZONGULDAK: [
    "EREĞLİ",
    "ALAPLI",
    "KİLİMLİ",
    "MERKEZ",
    "DEVREK",
    "ÇAYCUMA",
    "GÖKÇEBEY",
    "KOZLU",
  ],
  İZMİR: [
    "URLA",
    "ALİAĞA",
    "SELÇUK",
    "MENDERES",
    "NARLIDERE",
    "FOÇA",
    "KEMALPAŞA",
    "TİRE",
    "GAZİEMİR",
    "KARŞIYAKA",
    "BÜYÜKŞEHİR",
    "MENEMEN",
    "BAYRAKLI",
    "KİRAZ",
    "BALÇOVA",
    "SEFERİHİSAR",
    "BORNOVA",
    "ÇİĞLİ",
    "DİKİLİ",
    "KARABAĞLAR",
    "ÖDEMİŞ",
    "TORBALI",
    "GÜZELBAHÇE",
    "ÇEŞME",
    "KONAK",
    "BEYDAĞ",
    "BUCA",
    "BAYINDIR",
    "KARABURUN",
    "BERGAMA",
    "KINIK",
  ],
  KOCAELİ: [
    "BÜYÜKŞEHİR",
    "ÇAYIROVA",
    "İZMİT",
    "KÖRFEZ",
    "KARAMÜRSEL",
    "KANDIRA",
    "BAŞİSKELE",
    "GÖLCÜK",
    "DARICA",
    "GEBZE",
    "KARTEPE",
    "DERİNCE",
    "DİLOVASI",
  ],
  ARDAHAN: ["ÇILDIR", "MERKEZ", "POSOF", "DAMAL", "HANAK", "GÖLE"],
  TOKAT: [
    "ALMUS",
    "ARTOVA",
    "TURHAL",
    "YEŞİLYURT",
    "SULUSARAY",
    "MERKEZ",
    "ERBAA",
    "NİKSAR",
    "PAZAR",
    "REŞADİYE",
    "ZİLE",
    "BAŞÇİFTLİK",
  ],
  ÇANAKKALE: [
    "MERKEZ",
    "EZİNE",
    "GELİBOLU",
    "AYVACIK",
    "YENİCE",
    "BOZCAADA",
    "BİGA",
    "BAYRAMİÇ",
    "ECEABAT",
    "LAPSEKİ",
    "ÇAN",
    "GÖKÇEADA",
  ],
  HAKKARİ: ["ÇUKURCA", "YÜKSEKOVA", "ŞEMDİNLİ", "MERKEZ", "DERECİK"],
  ANTALYA: [
    "KAŞ",
    "GÜNDOĞMUŞ",
    "KORKUTELİ",
    "SERİK",
    "KEMER",
    "BÜYÜKŞEHİR",
    "KEPEZ",
    "ELMALI",
    "MURATPAŞA",
    "GAZİPAŞA",
    "ALANYA",
    "FİNİKE",
    "KONYAALTI",
    "AKSU",
    "İBRADI",
    "AKSEKİ",
    "DEMRE",
    "KUMLUCA",
    "MANAVGAT",
    "DÖŞEMEALTI",
  ],
  BAYBURT: ["MERKEZ", "AYDINTEPE", "DEMİRÖZÜ"],
  KIRŞEHİR: [
    "KAMAN",
    "MERKEZ",
    "BOZTEPE",
    "MUCUR",
    "AKÇAKENT",
    "ÇİÇEKDAĞI",
    "AKPINAR",
  ],
  KASTAMONU: [
    "AĞLI",
    "DOĞANYURT",
    "HANÖNÜ",
    "DEVREKANİ",
    "TOSYA",
    "İNEBOLU",
    "KÜRE",
    "BOZKURT",
    "DADAY",
    "CİDE",
    "İHSANGAZİ",
    "ÇATALZEYTİN",
    "ŞENPAZAR",
    "SEYDİLER",
    "MERKEZ",
    "AZDAVAY",
    "TAŞKÖPRÜ",
    "ABANA",
    "PINARBAŞI",
    "ARAÇ",
  ],
  IĞDIR: ["MERKEZ", "TUZLUCA", "KARAKOYUNLU", "ARALIK"],
  GAZİANTEP: [
    "YAVUZELİ",
    "ŞAHİNBEY",
    "ARABAN",
    "NURDAĞI",
    "OĞUZELİ",
    "KARKAMIŞ",
    "İSLAHİYE",
    "NİZİP",
    "ŞEHİTKAMİL",
    "BÜYÜKŞEHİR",
  ],
  AMASYA: [
    "MERZİFON",
    "GÖYNÜCEK",
    "MERKEZ",
    "GÜMÜŞHACIKÖY",
    "TAŞOVA",
    "HAMAMÖZÜ",
    "SULUOVA",
  ],
  MERSİN: [
    "TOROSLAR",
    "ERDEMLİ",
    "YENİŞEHİR",
    "GÜLNAR",
    "ÇAMLIYAYLA",
    "AYDINCIK",
    "TARSUS",
    "AKDENİZ",
    "MUT",
    "SİLİFKE",
    "BOZYAZI",
    "MEZİTLİ",
    "ANAMUR",
    "BÜYÜKŞEHİR",
  ],
  SAMSUN: [
    "TEKKEKÖY",
    "YAKAKENT",
    "LADİK",
    "BÜYÜKŞEHİR",
    "ASARCIK",
    "SALIPAZARI",
    "HAVZA",
    "BAFRA",
    "İLKADIM",
    "AYVACIK",
    "CANİK",
    "TERME",
    "VEZİRKÖPRÜ",
    "ONDOKUZMAYIS",
    "ALAÇAM",
    "ATAKUM",
    "ÇARŞAMBA",
    "KAVAK",
  ],
  TEKİRDAĞ: [
    "MURATLI",
    "SARAY",
    "MALKARA",
    "KAPAKLI",
    "ÇERKEZKÖY",
    "ŞARKÖY",
    "ERGENE",
    "ÇORLU",
    "SÜLEYMANPAŞA",
    "HAYRABOLU",
    "MARMARA EREĞLİSİ",
    "BÜYÜKŞEHİR",
  ],
  YOZGAT: [
    "AKDAĞMADENİ",
    "ÇAYIRALAN",
    "SARAYKENT",
    "MERKEZ",
    "YERKÖY",
    "BOĞAZLIYAN",
    "ÇANDIR",
    "ŞEFAATLİ",
    "AYDINCIK",
    "YENİFAKILI",
    "KADIŞEHRİ",
    "ÇEKEREK",
    "SARIKAYA",
    "SORGUN",
  ],
  MUĞLA: [
    "DALAMAN",
    "DATÇA",
    "SEYDİKEMER",
    "KAVAKLIDERE",
    "BÜYÜKŞEHİR",
    "MENTEŞE",
    "BODRUM",
    "YATAĞAN",
    "MARMARİS",
    "FETHİYE",
    "MİLAS",
    "ORTACA",
    "ULA",
    "KÖYCEĞİZ",
  ],
  BURSA: [
    "HARMANCIK",
    "MUDANYA",
    "ORHANELİ",
    "MUSTAFAKEMALPAŞA",
    "YILDIRIM",
    "NİLÜFER",
    "YENİŞEHİR",
    "OSMANGAZİ",
    "BÜYÜKORHAN",
    "GÜRSU",
    "İNEGÖL",
    "KESTEL",
    "İZNİK",
    "KELES",
    "BÜYÜKŞEHİR",
    "KARACABEY",
    "ORHANGAZİ",
    "GEMLİK",
  ],
  BİNGÖL: [
    "GENÇ",
    "SOLHAN",
    "ADAKLI",
    "MERKEZ",
    "YAYLADERE",
    "YEDİSU",
    "KARLIOVA",
    "KIĞI",
  ],
  KAYSERİ: [
    "YAHYALI",
    "BÜYÜKŞEHİR",
    "TALAS",
    "AKKIŞLA",
    "PINARBAŞI",
    "YEŞİLHİSAR",
    "DEVELİ",
    "SARIZ",
    "ÖZVATAN",
    "SARIOĞLAN",
    "HACILAR",
    "FELAHİYE",
    "İNCESU",
    "MELİKGAZİ",
    "KOCASİNAN",
    "TOMARZA",
    "BÜNYAN",
  ],
  KARAMAN: [
    "ERMENEK",
    "BAŞYAYLA",
    "SARIVELİLER",
    "KAZIMKARABEKİR",
    "MERKEZ",
    "AYRANCI",
  ],
  ADANA: [
    "YÜREĞİR",
    "KARAİSALI",
    "TUFANBEYLİ",
    "CEYHAN",
    "KARATAŞ",
    "FEKE",
    "İMAMOĞLU",
    "SAİMBEYLİ",
    "ÇUKUROVA",
    "SARIÇAM",
    "POZANTI",
    "BÜYÜKŞEHİR",
    "ALADAĞ",
    "YUMURTALIK",
    "SEYHAN",
    "KOZAN",
  ],
  MANİSA: [
    "GÖRDES",
    "SOMA",
    "YUNUSEMRE",
    "SALİHLİ",
    "KÖPRÜBAŞI",
    "TURGUTLU",
    "SARIGÖL",
    "SARUHANLI",
    "KULA",
    "SELENDİ",
    "AHMETLİ",
    "GÖLMARMARA",
    "BÜYÜKŞEHİR",
    "ŞEHZADELER",
    "KIRKAĞAÇ",
    "DEMİRCİ",
    "ALAŞEHİR",
    "AKHİSAR",
  ],
  AYDIN: [
    "KARACASU",
    "BÜYÜKŞEHİR",
    "KOÇARLI",
    "ÇİNE",
    "GERMENCİK",
    "İNCİRLİOVA",
    "DİDİM",
    "KUŞADASI",
    "KARPUZLU",
    "YENİPAZAR",
    "KUYUCAK",
    "EFELER",
    "SULTANHİSAR",
    "NAZİLLİ",
    "KÖŞK",
    "SÖKE",
    "BUHARKENT",
    "BOZDOĞAN",
  ],
  AKSARAY: [
    "SARIYAHŞİ",
    "AĞAÇÖREN",
    "GÜLAĞAÇ",
    "MERKEZ",
    "SULTANHANI",
    "ESKİL",
    "ORTAKÖY",
    "GÜZELYURT",
  ],
  DÜZCE: [
    "GÖLYAKA",
    "GÜMÜŞOVA",
    "ÇİLİMLİ",
    "MERKEZ",
    "CUMAYERİ",
    "AKÇAKOCA",
    "KAYNAŞLI",
    "YIĞILCA",
  ],
  KAHRAMANMARAŞ: [
    "AFŞİN",
    "EKİNÖZÜ",
    "DULKADİROĞLU",
    "TÜRKOĞLU",
    "GÖKSUN",
    "ONİKİŞUBAT",
    "NURHAK",
    "ANDIRIN",
    "ÇAĞLAYANCERİT",
    "BÜYÜKŞEHİR",
    "PAZARCIK",
    "ELBİSTAN",
  ],
  KIRIKKALE: [
    "BALIŞEYH",
    "KARAKEÇİLİ",
    "MERKEZ",
    "SULAKYURT",
    "KESKİN",
    "BAHŞILI",
    "YAHŞİHAN",
    "ÇELEBİ",
    "DELİCE",
  ],
  NEVŞEHİR: [
    "MERKEZ",
    "ACIGÖL",
    "KOZAKLI",
    "HACIBEKTAŞ",
    "DERİNKUYU",
    "ÜRGÜP",
    "GÜLŞEHİR",
    "AVANOS",
  ],
  MARDİN: [
    "KIZILTEPE",
    "BÜYÜKŞEHİR",
    "DERİK",
    "ARTUKLU",
    "SAVUR",
    "MİDYAT",
    "YEŞİLLİ",
    "NUSAYBİN",
    "ÖMERLİ",
    "MAZIDAĞI",
    "DARGEÇİT",
  ],
  ŞANLIURFA: [
    "SİVEREK",
    "SURUÇ",
    "BOZOVA",
    "HALİLİYE",
    "VİRANŞEHİR",
    "AKÇAKALE",
    "HİLVAN",
    "CEYLANPINAR",
    "BİRECİK",
    "KARAKÖPRÜ",
    "HALFETİ",
    "HARRAN",
    "EYYÜBİYE",
    "BÜYÜKŞEHİR",
  ],
  OSMANİYE: [
    "MERKEZ",
    "SUMBAS",
    "DÜZİÇİ",
    "BAHÇE",
    "KADİRLİ",
    "TOPRAKKALE",
    "HASANBEYLİ",
  ],
  BARTIN: ["AMASRA", "MERKEZ", "KURUCAŞİLE", "ULUS"],
  SİİRT: ["KURTALAN", "ŞİRVAN", "BAYKAN", "ERUH", "PERVARİ", "MERKEZ", "TİLLO"],
  ANKARA: [
    "KEÇİÖREN",
    "PURSAKLAR",
    "KALECİK",
    "AKYURT",
    "POLATLI",
    "NALLIHAN",
    "KIZILCAHAMAM",
    "BÜYÜKŞEHİR",
    "AYAŞ",
    "GÜDÜL",
    "KAHRAMANKAZAN",
    "ŞEREFLİKOÇHİSAR",
    "ÇANKAYA",
    "BEYPAZARI",
    "ÇUBUK",
    "YENİMAHALLE",
    "MAMAK",
    "ETİMESGUT",
    "EVREN",
    "ALTINDAĞ",
    "ELMADAĞ",
    "HAYMANA",
    "BALA",
    "ÇAMLIDERE",
    "GÖLBAŞI",
    "SİNCAN",
  ],
  ISPARTA: [
    "ATABEY",
    "GÖNEN",
    "MERKEZ",
    "AKSU",
    "GELENDOST",
    "SÜTÇÜLER",
    "SENİRKENT",
    "KEÇİBORLU",
    "ULUBORLU",
    "YENİŞARBADEMLİ",
    "ŞARKIKARAAĞAÇ",
    "YALVAÇ",
    "EĞİRDİR",
  ],
  ÇANKIRI: [
    "ŞABANÖZÜ",
    "YAPRAKLI",
    "ELDİVAN",
    "ÇERKEŞ",
    "KURŞUNLU",
    "ILGAZ",
    "ATKARACALAR",
    "ORTA",
    "KIZILIRMAK",
    "BAYRAMÖREN",
    "MERKEZ",
    "KORGUN",
  ],
  MUŞ: ["BULANIK", "MALAZGİRT", "HASKÖY", "KORKUT", "MERKEZ", "VARTO"],
  TUNCELİ: [
    "PÜLÜMÜR",
    "HOZAT",
    "PERTEK",
    "ÇEMİŞGEZEK",
    "MERKEZ",
    "MAZGİRT",
    "OVACIK",
    "NAZIMİYE",
  ],
  KÜTAHYA: [
    "PAZARLAR",
    "HİSARCIK",
    "EMET",
    "DOMANİÇ",
    "TAVŞANLI",
    "ÇAVDARHİSAR",
    "MERKEZ",
    "SİMAV",
    "GEDİZ",
    "ALTINTAŞ",
    "DUMLUPINAR",
    "ASLANAPA",
    "ŞAPHANE",
  ],
  BİTLİS: [
    "AHLAT",
    "HİZAN",
    "TATVAN",
    "MUTKİ",
    "ADİLCEVAZ",
    "MERKEZ",
    "GÜROYMAK",
  ],
  KARABÜK: ["MERKEZ", "YENİCE", "ESKİPAZAR", "OVACIK", "SAFRANBOLU", "EFLANİ"],
  EDİRNE: [
    "UZUNKÖPRÜ",
    "KEŞAN",
    "LALAPAŞA",
    "ENEZ",
    "MERİÇ",
    "MERKEZ",
    "HAVSA",
    "İPSALA",
    "SÜLOĞLU",
  ],
  NİĞDE: ["ÇİFTLİK", "ÇAMARDI", "ALTUNHİSAR", "BOR", "ULUKIŞLA", "MERKEZ"],
  AĞRI: [
    "ELEŞKİRT",
    "HAMUR",
    "TAŞLIÇAY",
    "DOĞUBAYAZIT",
    "PATNOS",
    "TUTAK",
    "DİYADİN",
    "MERKEZ",
  ],
};

export const days = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];
export const months = {
  1: "Ocak",
  2: "Şubat",
  3: "Mart",
  4: "Nisan",
  5: "Mayıs",
  6: "Haziran",
  7: "Temmuz",
  8: "Ağustos",
  9: "Eylül",
  10: "Ekim",
  11: "Kasım",
  12: "Aralık",
};
export const years = [
  2019 , 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004,
  2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996, 1995, 1994, 1993, 1992, 1991,
  1990, 1989, 1988, 1987, 1986, 1985, 1984, 1983, 1982, 1981, 1980, 1979, 1978,
  1977, 1976, 1975, 1974, 1973, 1972, 1971, 1970, 1969, 1968, 1967, 1966, 1965,
  1964, 1963, 1962, 1961, 1960, 1959, 1958, 1957, 1956, 1955, 1954, 1953, 1952,
  1951, 1950,
];

export const IF_RESULT_TEXTS = {
  // Elenenler "elendi" yazısı görmeyecek ama admin listede "Katıldı-Elendi" görecek
  1: {
    long: "Bölge Finalleri Sınavında yarışmaya hak kazandınız.",
    short: "Katılacak",
    adminText: "BF Katılacak",
  },
  2: {
    long: "Üzgünüz; Bölge Finalleri Sınavına katılmaya hak kazanamadınız.",
    short: "Olumsuz",
    adminText: "Elendi",
  },
  3: {
    long: "Bölge Finalleri Sınavında yarışmaya hak kazandınız.",
    short: "Feragat Eden Yarışmacı Yerine Katılacak",
    adminText: "Feragat Eden Yarışmacı Yerine Katılacak",
  },
  4: {
    long: "Üzgünüz; Bölge Finalleri Sınavına katılmaya hak kazanamadınız.",
    short: "Olumsuz(Feragat Etti)",
    adminText: "Feragat Etti",
  },
};

export const BF_RESULT_TEXTS = {
  // Elenenler "elendi" yazısı görmeyecek ama admin listede "Katıldı-Elendi" görecek
  1: {
    long: "Türkiye Finalleri Sınavında yarışmaya hak kazandınız.",
    short: "Katılacak",
    adminText: "TF Katılacak",
  },
  2: {
    long: "Üzgünüz; Türkiye Finalleri Sınavına katılmaya hak kazanamadınız.",
    short: "Olumsuz",
    adminText: "Elendi",
  },
  3: {
    long: "Türkiye Finalleri Sınavında yarışmaya hak kazandınız.",
    short: "Feragat Eden Yarışmacı Yerine Katılacak",
    adminText: "Feragat Eden Yarışmacı Yerine Katılacak",
  },
  4: {
    long: "Üzgünüz; Türkiye Finalleri Sınavına katılmaya hak kazanamadınız.",
    short: "Olumsuz(Feragat Etti)",
    adminText: "Feragat Etti",
  },
};

export const PDF_DOC_PROPS = {
  // Background resimleri png olarak değil de online bi yerden base64'e çevirerek kullanıyoruz. Kod ile base64'e çevirmek daha zahmetli geldi.
  
  if_katilim_ogrenci: {
    type: "if_katilim_ogrenci",
    file_name: "İl Finalleri Katılım Belgesi.pdf",
    background_image: if_katilim_base64,
    button_text: "Katılım Belgesi",
  },
  if_katilim_ogretmen: {
    type: "if_katilim_ogretmen",
    file_name: "İl Finalleri Katılım Belgesi.pdf",
    background_image: if_ogretmen_katilim_base64,
    button_text: "Öğretmen Katılım Belgesi",
  },
  if_katilim_okul: {
    type: "if_katilim_okul",
    file_name: "İl Finalleri Katılım Belgesi (OKUL).pdf",
    background_image: if_okul_katilim_base64,
    button_text: "Okul Katılım Belgesi",
  },
  if_basari: {
    type: "if_basari",
    file_name: "İl Finalleri Başarı Belgesi.pdf",
    background_image: if_basari_base64,
    button_text: "Başarı Belgesi",
  },
  if_basari_ogretmen: {
    type: "if_basari_ogretmen",
    file_name: "İl Finalleri Başarı Belgesi.pdf",
    background_image: if_ogretmen_basari_base64,
    button_text: "Öğretmen Başarı Belgesi",
  },
  if_basari_okul: {
    type: "if_basari_okul",
    file_name: "İl Finalleri Başarı Belgesi.pdf",
    background_image: if_okul_basari_base64,
    button_text: "Okul Başarı Belgesi",
  },
  if_sebf: {
    type: "if_sebf",
    file_name: "İl Finalleri Sosyal Etkinlik Formu.pdf",
    background_image: if_sebf_base64,
    button_text: "İl Finalleri SEBF",
  },

  bf_basari_ogrenci: {
    type: "bf_basari_ogrenci",
    file_name: "Bölge Finalleri Başarı Belgesi.pdf",
    background_image: bf_basari_ogrenci_base64,
    button_text: "Başarı Belgesi",
  },
  bf_katilim_ogrenci: {
    type: "bf_katilim_ogrenci",
    file_name: "Bölge Finalleri Katılım Belgesi.pdf",
    background_image: bf_katilim_ogrenci_base64,
    button_text: "Katılım Belgesi",
  },
  bf_basari_ogretmen: {
    type: "bf_basari_ogretmen",
    file_name: "Bölge Finalleri Başarı Belgesi.pdf",
    background_image: bf_basari_ogretmen_base64,
    button_text: "Öğretmen Başarı Belgesi",
  },
  bf_katilim_ogretmen: {
    type: "bf_katilim_ogretmen",
    file_name: "Bölge Finalleri Katılım Belgesi.pdf",
    background_image: bf_katilim_ogretmen_base64,
    button_text: "Öğretmen Katılım Belgesi",
  },
  bf_basari_okul: {
    type: "bf_basari_okul",
    file_name: "Bölge Finalleri Başarı Belgesi (OKUL).pdf",
    background_image: bf_basari_okul_base64,
    button_text: "Okul Başarı Belgesi",
  },
  bf_katilim_okul: {
    type: "bf_katilim_okul",
    file_name: "Bölge Finalleri Katılım Belgesi (OKUL).pdf",
    background_image: bf_katilim_okul_base64,
    button_text: "Okul Katılım Belgesi",
  },
  bf_sebf: {
    type: "bf_sebf",
    file_name: "Bölge Finalleri Sosyal Etkinlik Formu.pdf",
    background_image: bf_sebf_base64,
    button_text: "Bölge Finalleri SEBF",
  },
  
  tf_basari_ogrenci: {
    type: "tf_basari_ogrenci",
    file_name: "Türkiye Finalleri Başarı Belgesi.pdf",
    background_image: tf_basari_ogrenci_base64,
    button_text: "Başarı Belgesi",
  },
  tf_katilim_ogrenci: {
    type: "tf_katilim_ogrenci",
    file_name: "Türkiye Finalleri Katılım Belgesi.pdf",
    background_image: tf_katilim_ogrenci_base64,
    button_text: "Katılım Belgesi",
  },
  tf_basari_ogretmen: {
    type: "tf_basari_ogretmen",
    file_name: "Türkiye Finalleri Başarı Belgesi.pdf",
    background_image: tf_basari_ogretmen_base64,
    button_text: "Öğretmen Başarı Belgesi",
  },
  tf_katilim_ogretmen: {
    type: "tf_katilim_ogretmen",
    file_name: "Türkiye Finalleri Katılım Belgesi.pdf",
    background_image: tf_katilim_ogretmen_base64,
    button_text: "Öğretmen Katılım Belgesi",
  },
  tf_basari_okul: {
    type: "tf_basari_okul",
    file_name: "Türkiye Finalleri Başarı Belgesi (OKUL).pdf",
    background_image: tf_basari_okul_base64,
    button_text: "Okul Başarı Belgesi",
  },
  tf_katilim_okul: {
    type: "tf_katilim_okul",
    file_name: "Türkiye Finalleri Katılım Belgesi (OKUL).pdf",
    background_image: tf_katilim_okul_base64,
    button_text: "Okul Katılım Belgesi",
  },
  tf_sebf: {
    type: "tf_sebf",
    file_name: "Türkiye Finalleri Sosyal Etkinlik Formu.pdf",
    background_image: tf_sebf_base64,
    button_text: "Türkiye Finalleri SEBF",
  },
  tf_sebf_basari: {
    type: "tf_sebf_basari",
    file_name: "Türkiye Finalleri Sosyal Etkinlik Formu.pdf",
    background_image: tf_sebf_basari_base64,
    button_text: "Türkiye Finalleri SEBF",
  },
  tf_table_number_information:{
    type: "tf_table_number_information",
    file_name: "Sınav Giriş Bilgileri.pdf",
    background_image: base64_tf_table_no_info,
    button_text: "Sınav Giriş Bilgileriniz (Çıktı Alınız)",
  },
  tf_izin_yazisi: {
    type: "tf_izin_yazisi",
    file_name: "Türkiye Finalleri İzin Dilekçesi.pdf",
    background_image: base64_tf_izin_yazisi,
    button_text: "İzin Dilekçesi",
  },
};
